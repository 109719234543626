import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const FirstVisit = () => {
  return (
    <>
      <Helmet>
        <title>Pierwsza Wizyta - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Informacje na temat pierwszej wizyty - jak wygląda i jak się do niej przygotować." />
        <link rel="canonical" href="https://www.fizjocezar.pl/pierwsza-wizyta" />
      </Helmet>
      <Main />
    </>
  );
};
