import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferFifth = () => {
  return (
    <>
      <Helmet>
        <title>Rehabilitacja Seniorów - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Rehabilitacja seniorów to kompleksowy program fizjoterapeutyczny, który skupia się na przywracaniu sprawności, poprawie mobilności i wzmacnianiu mięśni poprzez indywidualne dostosowane ćwiczenia." />
        <link rel="canonical" href="https://www.fizjocezar.pl/rehabilitacja-seniorów" />
      </Helmet>
      <Main />
    </>
  );
};
