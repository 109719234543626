import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferSixth = () => {
  return (
    <>
      <Helmet>
        <title>Masaż - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Masaż to metoda, która poprzez zastosowanie różnorodnych technik, takich jak głaskanie, rozcieranie, ugniatanie, uderzanie mają zredukować napięcia mięśniowe, poprawić krążenie krwi, limfy i wspomaga proces regeneracji tkanek." />
        <link rel="canonical" href="https://www.fizjocezar.pl/masaż" />
      </Helmet>
      <Main />
    </>
  );
};
