// CookieConsent.jsx

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import CookieConsentCss from "./../../styles/layouts/CookieConsent/CookieConsentCss.module.css";

const CookieConsent = () => {
    const [show, setShow] = useState(true); // Początkowo ustawione na true, aby pokazać div

    const handleClose = () => {
        setShow(false); // Ustaw show na false po kliknięciu przycisku
    };

    useEffect(() => {
        // Funkcja do pokazania diva z opóźnieniem po 2 sekundach
        const showWithDelay = setTimeout(() => {
            setShow(true);
        }, 2000);

        // Czyszczenie timera po odmontowaniu komponentu
        return () => clearTimeout(showWithDelay);
    }, []); // Wywołaj efekt tylko raz przy montowaniu komponentu

    return (
        <div className={`${CookieConsentCss.cookieConsent} ${show ? CookieConsentCss.show : ''}`}>
            <p>
                Używamy plików cookie, aby poprawić komfort użytkowania. Korzystając z naszej strony internetowej,
                wyrażasz zgodę na używanie przez nas plików cookie.{" "}
                <a href={"/polityka-prywatności"}>Dowiedz się więcej.</a>
            </p>
            <button className={CookieConsentCss.closeButton} onClick={handleClose}>Akceptuję</button>
        </div>
    );
};

export default CookieConsent;
