import React from "react";
import { Main } from "../AboutMe/components/Main";
import { Helmet } from "react-helmet-async";

export const AboutMe = () => {
  return (
    <>
      <Helmet>
        <title>O Mnie - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Witaj! Nazywam się Cezary Ciecierski, jestem magistrem fizjoterapii oraz dyplomowanym technikiem masażystą. Oferuję rehabilitację z dojazdem do domu." />
        <link rel="canonical" href="https://www.fizjocezar.pl/o-mnie" />
      </Helmet>
      <Main />
    </>
  );
};
