import React, { ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>; // Definiuje typ komponentu
  roles: string[]; // Typ dla listy ról, które mają dostęp
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, roles, ...rest }) => {
  const userRole = localStorage.getItem('userRole');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userRole || !roles.includes(userRole)) {
          // Nie zalogowany lub brak odpowiedniej roli, przekieruj
          return <Redirect to="/" />;
        }

        // Użytkownik z odpowiednią rolą, renderuj komponent
        return <Component {...props} />;
      }}
    />
  );
};
