import React, { useEffect, useRef, useState } from "react";
import BookMain from "./../../../styles/layouts/Book/components/Main.module.css";
import { Link } from "react-router-dom";
import BookingData from "../../../models/BookingData";
import AppUserModle from "../../../models/AppUserModel";
import getUserData from "../../../utils/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getToken, isTokenExpired } from "../../../utils/auth";

type WeekDay = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

interface WorkingHours {
  startTime: string;
  endTime: string;
}

interface ConfigDays {
  workingHours: Record<WeekDay, WorkingHours | null>;
}

interface BookingConfigurationDTO {
  monthsInAdvance: number;
  blockedDates: string[];
  bookingEnabled: boolean;
  availableDays: string[];
}

interface BlockedDate {
  date: string;
  block: boolean;
}

interface BookingDTO {
  id: number;
  bookingDate: string;
  bookingStatus: string;
  isConfirmed: boolean;
  message: string;
}

interface EventDateTime {
  value: number;  // Unix timestamp in milliseconds
  dateOnly: boolean;
  timeZoneShift: number;
}

interface Event {
  id: string;
  summary: string;
  start: {
    dateTime: EventDateTime;
  };
  end: {
    dateTime: EventDateTime;
  };
  location?: string;
}

export const Main = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(-1);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedDay, setSelectedDay] = useState<number>(-1);
  const [currentForm, setCurrentForm] = useState("firstForm");
  const [showMessage, setShowMessage] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const errorMessageRef = useRef<HTMLParagraphElement>(null);
  const NAVBAR_HEIGHT = 50;
  const [userData, setUserData] = useState<AppUserModle | null>(null);
  const [bookingData, setBookingData] = useState<BookingData>(
    new BookingData(null, 0, "")
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState<Error | null>(null);

  const [blockedDates, setBlockedDates] = useState<BlockedDate[]>([]);

  const [config, setConfig] = useState<BookingConfigurationDTO | null>(null);

  const [bookings, setBookings] = useState<BookingDTO[]>([]);
  const maxLength = 255;
  const charactersLeft = maxLength - bookingData.message.length;

  const [events, setEvents] = useState<Event[]>([]);

  const [workingHours, setWorkingHours] = useState<ConfigDays | null>(null);

  const [isBookingsEnabled, setIsBookingsEnabled] = useState<boolean | null>(null);

  const fetchIsBookingsEnabled = async () => {
    try {
      const response = await fetch(`${apiUrl}/v1/config/isBookingsEnabled`);

      if (!response) {
        throw new Error("Failed to fetch booking status");
      }

      const data: boolean = await response.json();
      setIsBookingsEnabled(data);
      // console.log(data);
    } catch (err: any) {
      setError(err);
    }
  }

  useEffect(() => {
    fetchIsBookingsEnabled();
    // console.log("isBookingsEnabled ", isBookingsEnabled);
  }, []);

  // Drugi useEffect, który zareaguje na zmianę isBookingsEnabled
  useEffect(() => {
    
    // console.log("isBookingsEnabled ", isBookingsEnabled);
    if (isBookingsEnabled !== null && !isBookingsEnabled) {
      setCurrentForm("otherForm");
    }
  }, [isBookingsEnabled]);

  const fetchWorkingHours = async () => {
    try {
      const response = await fetch(`${apiUrl}/v1/config/getWorkingHours`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Record<WeekDay, WorkingHours> = await response.json(); // Adjust type if needed
      const initialConfig: ConfigDays = {
        workingHours: {
          MONDAY: null,
          TUESDAY: null,
          WEDNESDAY: null,
          THURSDAY: null,
          FRIDAY: null,
          SATURDAY: null,
          SUNDAY: null,
        }
      };

      // Populate the initialConfig with data from the API
      for (const day of Object.keys(initialConfig.workingHours) as WeekDay[]) {
        if (day in data) {
          initialConfig.workingHours[day] = {
            startTime: data[day]?.startTime ?? '7:00', // Default start time if not provided
            endTime: data[day]?.endTime ?? '17:00' // Default end time if not provided
          };
        }
      }

      // const data: WorkingHours = await response.json();
      setWorkingHours(initialConfig);
    } catch (err: any) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchWorkingHours();
  }, [])

  const fetchEvents = async (selectedDate: Date) => {
    const fromDate = new Date();
    const toDate = selectedDate;

    try {
      const response = await fetch(
        `${apiUrl}/v1/calendar/events?calendarId=primary&from=${fromDate.toISOString()}&to=${toDate.toISOString()}`,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Event[] = await response.json();

      setEvents(data);

    } catch (error: any) {
      // console.log("error with events: ", error.message);
    }
  };

  useEffect(() => {
    if (token) {
      getUserData(token).then((data) => {
        setUserData(data);
      });
    }

    if (showMessage && messageRef.current) {
      const navbar = document.querySelector("#navbar"); // Znajdujemy navbar za pomocą jego identyfikatora
      const navbarHeight = navbar ? navbar.clientHeight : 0;

      const offsetTop =
        messageRef.current.offsetTop - navbarHeight - NAVBAR_HEIGHT; // Obliczamy docelowy offset

      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    getBookingConfig();
    getBookings();
  }, [showMessage]);

  useEffect(() => {
    if (config?.monthsInAdvance !== undefined) {
      const timeNow = new Date();
      const monthsToAdd = config?.monthsInAdvance || 0;
      timeNow.setMonth(timeNow.getMonth() + monthsToAdd);
      fetchEvents(timeNow);
    }
  }, [config]);

  useEffect(() => {
    const token = getToken();
    if (token && !isTokenExpired(token)) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setUserData(null);
    }

  }, [])

  const monthsInPolish = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];

  const currentDate = new Date();
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    currentDate.getMonth()
  );
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  const goToPreviousMonth = () => {
    let newMonth = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
    let newYear = currentMonthIndex === 0 ? currentYear - 1 : currentYear;
    setCurrentMonthIndex(newMonth);
    setCurrentYear(newYear);
  };

  const goToNextMonth = () => {
    let newMonth = currentMonthIndex === 11 ? 0 : currentMonthIndex + 1;
    let newYear = currentMonthIndex === 11 ? currentYear + 1 : currentYear;
    setCurrentMonthIndex(newMonth);
    setCurrentYear(newYear);
  };

  const currentMonth = monthsInPolish[currentMonthIndex];

  const renderTimes = () => {
    const availableTimes: string[] = [];

    if (selectedDate && bookingData.date) {
      const dayOfWeek = bookingData.date.toLocaleDateString('en-EN', { weekday: 'long' }).toUpperCase() as WeekDay;
      const workHours = workingHours?.workingHours?.[dayOfWeek];

      if (!workHours) {
        return <span>Fizjoterapeuta nie pracuje w tym dniu.</span>;
      }

      let hour = parseInt(workHours.startTime.split(":")[0]);
      let minute = parseInt(workHours.startTime.split(":")[1]);
      const endHour = parseInt(workHours.endTime.split(":")[0]);
      const endMinute = parseInt(workHours.endTime.split(":")[1]);

      while (hour < endHour || (hour === endHour && minute < endMinute)) {
        // Format start time
        const startTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

        // Calculate end time
        let endHourSlot = hour;
        let endMinuteSlot = minute + 60; // 1 hour later
        if (endMinuteSlot >= 60) {
          endMinuteSlot -= 60;
          endHourSlot += 1;
        }

        // Format end time
        const endTime = `${endHourSlot.toString().padStart(2, '0')}:${endMinuteSlot.toString().padStart(2, '0')}`;

        // Push the time slot if within working hours
        if (endHourSlot < endHour || (endHourSlot === endHour && endMinuteSlot <= endMinute)) {
          // Check if this time slot is available
          if (isTimeSlotAvailable(startTime, endTime)) {
            availableTimes.push(`${startTime} - ${endTime}`);
          }
        } else {
          break;
        }

        // Increment by 1 hour 30 minutes (next slot starts 30 minutes after current ends)
        minute += 90;
        if (minute >= 60) {
          minute -= 60;
          hour += 1;
        }
        if (minute >= 60) {
          minute -= 60;
          hour += 1;
        }
      }

      return availableTimes.map((time, index) => (
        <span
          key={time + index}
          className={`${BookMain.time} ${selectedTime === time ? BookMain.selected : ""}`}
          onClick={() => {
            const [start, end] = time.split(" - ");
            const [hour, minute] = start.split(":");
            handleTimeClick(time, parseInt(hour), parseInt(minute));
          }}
        >
          {time}
        </span>
      ));
    }

    return null;
  }

  // Helper function to check if a time slot is available
  const isTimeSlotAvailable = (startTime: string, endTime: string): boolean => {
    if (!selectedDate) {
      return false;
    }

    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const selectedDateStart = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), startHour, startMinute));
    const selectedDateEnd = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), endHour, endMinute));

    // Compare each booking's time with the given time slot
    for (const booking of bookings) {
      const bookingDate = new Date(booking.bookingDate);
      const bookingStart = new Date(Date.UTC(bookingDate.getUTCFullYear(), bookingDate.getUTCMonth(), bookingDate.getUTCDate(), bookingDate.getUTCHours(), bookingDate.getUTCMinutes()));
      const bookingEnd = new Date(bookingStart);
      bookingEnd.setUTCHours(bookingEnd.getUTCHours() + 1); // Booking lasts 1 hour

      if (selectedDateStart < bookingEnd && selectedDateEnd > bookingStart) {
        return false;
      }
    }

    // Compare each event's time with the given time slot
    for (const event of events) {
      const eventStartUTC = new Date(event.start.dateTime.value);
      const eventEndUTC = new Date(event.end.dateTime.value);

      // Funkcja, która zwraca odpowiednie przesunięcie czasowe (2h w lecie, 1h poza latem)
      const adjustForTimezone = (date: Date): Date => {
        const localDate = new Date(date);

        // Sprawdź przesunięcie czasowe w minutach
        const timezoneOffset = localDate.getTimezoneOffset();

        // Jeśli przesunięcie to -120 minut (UTC+2), jesteśmy w czasie letnim
        if (timezoneOffset === -120) {
          // Dodaj 2 godziny
          localDate.setHours(localDate.getHours() + 2);
        } else {
          // W przeciwnym razie dodaj 1 godzinę
          localDate.setHours(localDate.getHours() + 1);
        }

        return localDate;
      };

      // Dopasowanie strefy czasowej dla eventStartUTC i eventEndUTC
      const eventStartAdjusted = adjustForTimezone(eventStartUTC);
      const eventEndAdjusted = adjustForTimezone(eventEndUTC);

      const bufferMinutes = 30;
      const bufferMillis = bufferMinutes * 60 * 1000;

      // A: Start date of the first date range
      // B: End date of the first date range
      // C: Start date of the second date range
      // D: End date of the second date range

      if (!((selectedDateEnd.getTime() + bufferMillis <= eventStartAdjusted.getTime()) || (selectedDateStart.getTime() - bufferMillis >= eventEndAdjusted.getTime()))) {
        // console.log("false");
        return false;
      }
    }

    // console.log("true");
    return true;
  }

  function convertUTCToLocal(date: { toLocaleString: (arg0: string, arg1: { timeZone: string; }) => string | number | Date; }) {
    // Konwersja z UTC na lokalny czas
    return new Date(date.toLocaleString("en-US", { timeZone: "Europe/Warsaw" }));
  }

  const handleTimeClick = (
    time: string,
    selectedHour: number,
    selectedMinutes: number
  ) => {
    setBookingData((prevState) => {
      // Tylko modyfikuj datę, jeśli prevState.date jest już ustawione
      if (prevState.date) {
        const currentTime = new Date(prevState.date);
        currentTime.setUTCHours(selectedHour, selectedMinutes);
        return {
          ...prevState,
          date: currentTime,
        };
      } else {
        // Jeśli prevState.date nie jest ustawione, zwróć poprzedni stan bez zmian
        // Możesz tutaj również zdecydować, czy chcesz wykonać jakieś inne akcje
        return prevState;
      }
    });

    setSelectedTime(time); // Aktualizacja wybranego czasu dla UI
  };

  const handleDateClick = (day: number) => {
    // Ustawianie daty bez zmiany czasu
    const newDate = new Date(Date.UTC(currentYear, currentMonthIndex, day));

    // Zachowaj aktualnie wybrany czas, jeśli jest dostępny
    if (bookingData.date) {
      newDate.setUTCHours(
        bookingData.date.getUTCHours(),
        bookingData.date.getUTCMinutes()
      );
    }

    setBookingData((prevState) => ({
      ...prevState,
      date: newDate,
    }));
    setSelectedDate(newDate); // Opcjonalnie, jeśli nadal chcesz przechowywać oddzielny stan dla wyświetlania
    setSelectedDay(day); // Aktualizuj wybrany dzień dla UI
    setSelectedTime("");
  };

  const getBookings = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiUrl}/v1/booking/getBookings`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Nie udało się pobrać rezerwacji");
      }

      const data: BookingDTO[] = await response.json();
      setBookings(data);
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err);
      } else {
        // console.log("Unnkwonwn error");
      }
    }
  };

  const getBookingConfig = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      // console.log("Brak tokenu autoryzacyjnego");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/v1/config/getBookingConfig`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data: BookingConfigurationDTO = await response.json();
      setConfig(data);

      const updateBlockedDates = data.blockedDates.map((date) => ({
        date: date,
        block: true,
      }));
      setBlockedDates(updateBlockedDates);
    } catch (error) {
      console.error("Failed to fetch booking configuration:", error);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Usuń czas z obecnej daty

  const maxDateAllowed = new Date(
    today.getFullYear(),
    today.getMonth() + (config?.monthsInAdvance || 0),
    today.getDate()
  );

  const isDateBlocked = (dateString: string) => {
    return config?.blockedDates.includes(dateString);
  };

  const dayClass = (dayOfMonth: number, indexOfDay: number) => {

    const dayDate = new Date(currentYear, currentMonthIndex, dayOfMonth);
    dayDate.setHours(0, 0, 0, 0); // Usuń czas z daty dnia

    let className = hoveredDay === indexOfDay ? BookMain.hovered : "";
    className += dayOfMonth === selectedDay ? ` ${BookMain.selected}` : "";

    let isDaySelectable = true; // Domyślnie zakładamy, że dzień jest wybieralny

    const dayOfWeekNames = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const dayOfWeekName = dayOfWeekNames[dayDate.getDay()];

    if (isLoggedIn) {
      if (dayDate <= today) {
        className += ` ${BookMain.pastDay}`;
        isDaySelectable = false; // Dzień jest przeszły
      } else if (dayDate >= maxDateAllowed) {
        className += ` ${BookMain.futureBlockedDay}`;
        isDaySelectable = false; // Dzień jest poza zakresem
      } else if (isDateBlocked(dayDate.toLocaleDateString("en-CA"))) {
        className += ` ${BookMain.blockedDay}`;
        isDaySelectable = false; // Dzień jest zablokowany
      } else if (config && !config.availableDays.includes(dayOfWeekName)) {
        className += ` ${BookMain.unavailableDay}`;
        isDaySelectable = false;
      } else {
        className += ` ${BookMain.availableDays}`;
        isDaySelectable = true;
      }
    }
    return { className, isDaySelectable };
  };

  const generateDaysInMonth = () => {
    const firstDayOfMonth = new Date(
      currentYear,
      currentMonthIndex,
      1
    ).getDay();
    const daysInMonth = new Date(
      currentYear,
      currentMonthIndex + 1,
      0
    ).getDate();

    let days = [];
    let day = 1;

    for (let i = 0; i < 6; i++) {
      let cells = [];
      let daysRendered = 0; // Zmienna do śledzenia renderowanych dni
      for (let j = 0; j < 7; j++) {
        const indexOfDay = i * 7 + j;
        const dayOfMonth = indexOfDay - firstDayOfMonth + 1;

        if ((i === 0 && j < firstDayOfMonth) || dayOfMonth > daysInMonth) {
          cells.push(<td key={`${i}${j}`}></td>);
        } else {
          const { className, isDaySelectable } = dayClass(
            dayOfMonth,
            indexOfDay
          );

          cells.push(
            <td
              key={`${i}${j}`}
              onMouseEnter={() =>
                isDaySelectable ? setHoveredDay(indexOfDay) : null
              }
              onMouseLeave={() => (isDaySelectable ? setHoveredDay(-1) : null)}
              onClick={() =>
                isDaySelectable ? handleDateClick(dayOfMonth) : null
              }
              className={dayClass(dayOfMonth, indexOfDay).className}
            >
              {dayOfMonth <= daysInMonth ? <span>{dayOfMonth}</span> : ""}
            </td>
          );
        }
      }
      days.push(<tr key={i}>{cells}</tr>);
    }

    return days;
  };

  const token = localStorage.getItem("token");

  const submitBooking = async (bookingData: BookingData) => {
    setIsLoading(true);

    const payload = {
      bookingDate: bookingData.date ? bookingData.date.toISOString() : null,
      userId: userData?.id,
      message: bookingData.message,
    };

    try {
      const response = await fetch(
        `${apiUrl}/v1/booking/book`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

      if (!response.ok) {
        if (response.headers.get("Content-Type")?.includes("application/json")) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message || "Unknown error occurred");
        } else {
          const errorText = await response.text();
          throw new Error(errorText || "Unknown error occurred");
        }
      }

      const bookingResponse = await response.json();
    } catch (error) {
      console.error("Error during booking submission:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClick = async () => {
    setErrorMessage('');
    if (currentForm === "firstForm") {
      if (selectedDate === null || selectedTime === "") {
        setShowMessage(true);
      } else {
        setShowMessage(false);
        if (isLoggedIn) {
          setCurrentForm("summary");
        } else {
          setCurrentForm("loginOrRegister");
        }
      }
    } else if (currentForm === "summary") {
      try {
        await submitBooking(bookingData);
        setCurrentForm("postBookingInstructions");
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error during booking submission: ", error);
          setErrorMessage(error.message);
          setIsLoading(false);
          setTimeout(() => {
            errorMessageRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);
        }
      }
    }

  };

  const daysInMonth = generateDaysInMonth();

  function formatDateToPolishFullDate(date: Date | null): string {
    if (!date) return "Nie wybrano daty";

    return date.toLocaleDateString("pl-PL", {
      weekday: "long", // pełna nazwa dnia tygodnia
      day: "numeric", // numer dnia
      month: "long", // pełna nazwa miesiąca
      year: "numeric", // numer roku
    });
  }

  return (
    <>
      <div className={BookMain.containerMain}>
        <div className={BookMain.aboutSection}>
          <h1>Zarezerwuj</h1>
        </div>
        <div className={BookMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/zarezerwuj">Zarezerwuj</Link>
        </div>
      </div>
      <main className={BookMain.mainSection}>
        <div className={BookMain.formSection}>
          <h2>Zarezerwuj wizytę</h2>

          {currentForm === "otherForm" && (
            <div className={BookMain.loginOrRegister}>
              <p>Rezerwacje są aktualnie wyłączone</p>
            </div>
          )}

          {currentForm === "firstForm" && (
            <form className={BookMain.formContaincer} action="">
              <h4>Kiedy ma się odbyć wizyta?</h4>
              <div className={BookMain.formMain}>
                <div className={BookMain.datapicker}>
                  <div className={BookMain.datapickerContainer}>
                    {showMessage && (
                      <div className={BookMain.message} ref={messageRef}>
                        Wybierz datę i godzinę!
                      </div>
                    )}
                    <table className={BookMain.datapickerTable}>
                      <thead>
                        <tr>
                          <th
                            className={BookMain.prev}
                            onClick={goToPreviousMonth}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                          </th>
                          <th className={BookMain.switch} colSpan={5}>
                            {currentMonth} {currentYear}
                          </th>
                          <th className={BookMain.next} onClick={goToNextMonth}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                          </th>
                        </tr>
                        <tr>
                          <th>Niedz</th>
                          <th>Pon</th>
                          <th>Wt</th>
                          <th>Śrd</th>
                          <th>Czw</th>
                          <th>Pt</th>
                          <th>Sob</th>
                        </tr>
                      </thead>
                      <tbody>{daysInMonth}</tbody>
                    </table>
                  </div>
                </div>
                <div className={BookMain.legend}>
                  <div className={BookMain.legendItem}>
                    <div className={BookMain.colorBoxOne}></div>
                    <div className={BookMain.labelInfo}>
                      <p>Dostępne dni do rezerwacji</p>
                    </div>
                  </div>
                  <div className={BookMain.legendItem}>
                    <div className={BookMain.colorBoxFour}></div>
                    <div className={BookMain.labelInfo}>
                      <p>Wybrany dzień do rezerwacji</p>
                    </div>
                  </div>
                  <div className={BookMain.legendItem}>
                    <div className={BookMain.colorBoxThree}></div>
                    <div className={BookMain.labelInfo}>
                      <p>Niedostępne dni do rezerwacji</p>
                    </div>
                  </div>
                </div>
                <div className={BookMain.timepickerContainer}>
                  <div className={BookMain.dataTimePicked}>
                    {formatDateToPolishFullDate(bookingData.date)}{" "}
                    {selectedTime}
                  </div>
                  <div className={BookMain.timepicker}>
                    {renderTimes()}
                  </div>
                </div>
              </div>
              <div className={BookMain.buttonContainer}>
                <button type="button" onClick={handleNextClick}>
                  DALEJ
                </button>
              </div>
            </form>
          )}

          {currentForm === "loginOrRegister" && (
            <div className={BookMain.loginOrRegister}>
              <p>Aby kontynuować zaloguj lub zarejestruj się</p>
            </div>
          )}

          {currentForm === "summary" && (
            <>
              {isLoading ?
                <div className={BookMain.spinerContainer}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
                :
                <form className={BookMain.formContaincer} action="">
                  <h4>Podsumowanie</h4>
                  {errorMessage && (
                    <p ref={errorMessageRef} style={{ color: "red" }}>
                      {errorMessage}
                    </p>
                  )}
                  <div className={BookMain.reservationConfirmationContainer}>
                    <div className={BookMain.reservationConfirmation}>
                      <p>
                        Drogi(a)
                        <strong>
                          {" "}
                          {userData?.firstName} {userData?.lastName},
                        </strong>
                        <br />
                        prosimy o zapoznanie się i potwierdzenie szczegółów
                        rezerwacji:
                      </p>
                    </div>
                  </div>
                  <div className={BookMain.reservationDetails}>
                    <div className={BookMain.bookingInfo}>
                      <div className={BookMain.dateTime}>
                        <div className={BookMain.dateTimeDesc}>
                          <span>Data i godzina rezerwacji</span>
                        </div>
                        <div className={BookMain.dateTimeVal}>
                          {bookingData.date
                            ? bookingData.date.toLocaleString("pl-PL", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              timeZone: "UTC"
                            })
                            : "Nie wybrano daty"}
                        </div>
                        <div className={BookMain.bottomBorder}>
                          <hr />
                        </div>
                      </div>
                      <div className={BookMain.serviceType}>
                        <div className={BookMain.serviceTypeDesc}>
                          <span>Usługa</span>
                        </div>
                        <div className={BookMain.serviceTypeVal}>Fizjoterapia</div>
                        <div className={BookMain.bottomBorder}>
                          <hr />
                        </div>
                      </div>
                      <div className={BookMain.serviceType}>
                        <div className={BookMain.serviceTypeDesc}>
                          <span>Adres</span>
                        </div>
                        <div className={BookMain.serviceTypeVal}>
                          {userData?.address?.city}, {userData?.address?.street}{" "}
                          {userData?.address?.buildingNumber} /{" "}
                          {userData?.address?.flatNumber}
                        </div>
                        <div className={BookMain.bottomBorder}>
                          <hr />
                        </div>
                      </div>
                      <p style={{ color: "red" }}>
                        Zaktualizuj swój profil w celu zmiany <Link to="/konto/uzytkownik">danych adresowych.</Link>
                      </p>
                      <p style={{ color: "red" }}>
                        Godzina wizyty może ulec zmianie z powodu utrudnień na drodze.
                      </p>
                    </div>
                    <div className={BookMain.totalAmount}>
                      <div className={BookMain.totalAmountContainer}>
                        <h3 className={BookMain.totalLabel}>Całkowita kwota</h3>
                        <h3 className={BookMain.totalPrice}>200 zł</h3>
                      </div>
                    </div>
                    <div className={BookMain.leaveMessage}>
                      <label>Zostaw wiadomość</label>
                      <textarea
                        maxLength={255}
                        placeholder="Zostaw wiadomość"
                        value={bookingData.message}
                        onChange={(e) =>
                          setBookingData({
                            ...bookingData,
                            message: e.target.value,
                          })
                        }
                      ></textarea>
                      <div>Pozostało {charactersLeft} znaków</div>
                    </div>
                  </div>
                  <div className={BookMain.buttonContainer}>
                    <button type="button" onClick={handleNextClick}>
                      Zarezerwuj
                    </button>
                  </div>
                </form>
              }
            </>
          )}

          {currentForm === "postBookingInstructions" && (
            <div className={BookMain.InfoForm}>
              <p>
                Przejdź do zakładki <Link to="/konto/uzytkownik">Konto</Link> aby śledzić
                twoją rezerwację.
              </p>
            </div>
          )}
        </div>
      </main>
    </>
  );
};
