import React from "react";
import FirstVisitCSS from "./../../../styles/layouts/FirstVisit/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={FirstVisitCSS.containerMain}>
        <div className={FirstVisitCSS.aboutSection}>
          <h1>Pierwsza wizyta</h1>
        </div>
        <div className={FirstVisitCSS.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/pierwsza-wizyta">Pierwsza wizyta</Link>
        </div>
      </div>
      <main className={FirstVisitCSS.sectionMain}>
        <div className={FirstVisitCSS.sectionTitle}>
          <h3>Przygotowanie do pierwszej wizyty</h3>
          <span></span>
        </div>
        <div className={FirstVisitCSS.textWidget}>
          <div className={FirstVisitCSS.titleBox}>
            <p className={FirstVisitCSS.number}>1</p>
            <h5>Rezerwacja wizyty</h5>
          </div>
          <p className={FirstVisitCSS.subtitle}>
            Na pierwsze spotkanie możesz umówić się telefonicznie. Istnieje możliwość rezerwacji online otrzymasz potwierdzenie wizyty na stronie w zakładce konto i na email.
          </p>
          <div className={FirstVisitCSS.titleBox}>
            <p className={FirstVisitCSS.number}>2</p>
            <h5>Dokumentacja medyczna</h5>
          </div>
          <p className={FirstVisitCSS.subtitle}>
            Przygotuj kartę informacyjną ze szpitala i badania obrazowe (RTG, USG, MRI) jeżeli je posiadasz.
          </p>
          <div className={FirstVisitCSS.titleBox}>
            <p className={FirstVisitCSS.number}>3</p>
            <h5>Ubiór</h5>
          </div>
          <p className={FirstVisitCSS.subtitle}>
            Wygodny i swobodny strój.
          </p>
        </div>
        <div className={FirstVisitCSS.titleBox}>
          <p className={FirstVisitCSS.number}>4</p>
          <h5>Cele i oczekiwania</h5>
        </div>
        <p className={FirstVisitCSS.subtitle}>
          Zastanów się jaki cel chcesz osiągnąć w trakcie rehabilitacji.
        </p>
        <div className={FirstVisitCSS.sectionTitle}>
          <h3>Jak wygląda pierwsza wizyta?</h3>
          <span></span>
        </div>
        <div>
          <div className={FirstVisitCSS.firstVisit1}>
            <div className={FirstVisitCSS.fv1Col1}>
              <img
                src={require("./../../../images/FirstVisit/Zdjęcie3.jpg")}
                alt="fc"
              />
            </div>
            <div className={FirstVisitCSS.fv1Col2}>
              <h4>Wywiad</h4>
              <p>
                Szczegółowy wywiad pomaga dokładnie poznać główną przyczynę zgłoszenia się pacjenta do fizjoterapeuty,
                lokalizacje dolegliwości, jak objawy zmieniają się w ciągu dnia i od jak dawna występują.
                Pozwala postawić pierwsze hipotezy dotyczące przyczyny problemu, zaplanowanie pierwszej terapii.
              </p>
            </div>
          </div>
          <div className={FirstVisitCSS.firstVisit1}>
            <div className={FirstVisitCSS.fv2Col1}>
              <h4>Badanie</h4>
              <p>
                To etap, w którym ocenia się postawę ciała, zakresy ruchomości, przeprowadza się testy funkcjonalne.
                Te czynności pomagają w znaleźć potencjalne źródła dolegliwości bólowych i niewłaściwe wzorce ruchowe.
              </p>
            </div>
            <div className={FirstVisitCSS.fv2Col2}>
              <img
                src={require("./../../../images/FirstVisit/Zdjęcie4.jpg")}
                alt="fc"
              />
            </div>
          </div>
          <div className={FirstVisitCSS.firstVisit1}>
            <div className={FirstVisitCSS.fv3Col1}>
              <img
                src={require("./../../../images/FirstVisit/Zdjęcie5.jpg")}
                alt="fc"
              />
            </div>
            <div className={FirstVisitCSS.fv3Col2}>
              <h4>Wstępna terapia i ponowne badanie</h4>
              <p>
                Wykonywanie odpowiednich technik terapeutycznych. Ponowne badanie ma na celu potwierdzenie lub zaprzeczenie hipotez postawionych w wywiadzie.
                Te czynności pozwalają na ustalenie dalszego planu rehabilitacji.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
