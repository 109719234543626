import React from "react";
import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={OfferFirstMain.containerMain}>
        <div className={OfferFirstMain.aboutSection}>
          <h1>Rehabilitacja Seniorów</h1>
        </div>
        <div className={OfferFirstMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
          <Link to="/rehabilitacja-seniorów">Rehabilitacja Seniorów</Link>
        </div>
      </div>

      <main className={OfferFirstMain.mainSection}>
        <div className={OfferFirstMain.mainDesc}>
          <div className={OfferFirstMain.textContent}>
            <p>
              <b>Rehabilitacja seniorów</b> to kompleksowy program fizjoterapeutyczny, który skupia się na przywracaniu sprawności,
              poprawie mobilności i wzmacnianiu mięśni poprzez indywidualne dostosowane ćwiczenia.
              Celem jest poprawa jakości życia poprzez zwiększenie niezależności i komfortu codziennego funkcjonowania seniorów.
            </p>
          </div>
        </div>
        <div className={OfferFirstMain.imgContainer}>
          <img
            src={require("./../../../images/Offerts/rehabilitacja-seniorów/Zdjęcie10.jpg")}
            alt=""
          />
        </div>
        <div className={OfferFirstMain.row}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Jakie schorzenia leczymy?</h3>
            <span></span>
          </div>
          <ul>
            <li>Złamania i urazy</li>
            <li>Choroby układu sercowo-naczyniowego</li>
            <li>Choroby przewlekłe</li>
            <li>Problemy z równowagą i stabilnością</li>
            <li>Ograniczenia związane z wiekiem</li>
            <li>Przewlekłe bóle</li>
            <li>Stan po długotrwałym pobycie w szpitalu</li>
          </ul>
        </div>
        <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Korzyści terapii</h3>
            <span></span>
          </div>
          <ul>
            <li>Poprawa funkcji ruchowych</li>
            <li>Zmniejszenie bólu</li>
            <li>Poprawa równowagi i stabilności</li>
            <li>Samodzielność i niezależność</li>
            <li>Poprawa sprawności</li>
            <li>Redukcja ryzyka upadków</li>
          </ul>
        </div>
      </main>
    </>
  );
};
