import React, { useEffect, useState } from "react";
import AccountMain from "./../../../../styles/layouts/Account/components/Main.module.css";
import AdminPanelCSS from "./../../../../styles/layouts/Account/components/AdminPanel.module.css";
import { Link } from "react-router-dom";
import AppUserModle from "../../../../models/AppUserModel";
import getUserData from "../../../../utils/UserService";
import BookingsToConfirm from "../BookingsToConfirm";
import MessagesComponent from "./MessagesComponent";

interface WorkingHours {
  startTime: string;
  endTime: string;
}

interface WorkingHoursConfig {
  [day: string]: WorkingHours;
}

interface BookingConfigurationDTO {
  monthsInAdvance: number;
  blockedDates: string[];
  bookingEnabled: boolean;
  availableDays: string[];
  workingHours: Record<string, WorkingHours>;
}

interface BlockedDate {
  date: string;
  block: boolean;
}

type DayOfWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

type SelectedDays = {
  [key in DayOfWeek]: boolean;
};

export const AdminPanel = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const token = localStorage.getItem("token");
  const [displayedSection, setDisplayedSection] = useState<string | null>(null);
  const [activLink, setActivLink] = useState("");
  const [selectedMessage, setSelectedMessage] = useState<number | null>(null);
  const [userData, setUserData] = useState<AppUserModle | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [monthsInAdvance, setMonthsInAdvance] = useState<number>(3);
  const [isFetching, setIsFetching] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [blockedDates, setBlockedDates] = useState<BlockedDate[]>([]);

  const initialWorkingHoursConfig: WorkingHoursConfig = {
    MONDAY: { startTime: '08:00:00', endTime: '17:00:00' },
    TUESDAY: { startTime: '08:00:00', endTime: '17:00:00' },
    WEDNESDAY: { startTime: '08:00:00', endTime: '17:00:00' },
    THURSDAY: { startTime: '08:00:00', endTime: '17:00:00' },
    FRIDAY: { startTime: '08:00:00', endTime: '17:00:00' },
    SATURDAY: { startTime: '08:00:00', endTime: '17:00:00' },
    SUNDAY: { startTime: '08:00:00', endTime: '17:00:00' }
  };

  const [workingHoursConfig, setWorkingHoursConfig] = useState<WorkingHoursConfig>(initialWorkingHoursConfig);

  const [config, setConfig] = useState<BookingConfigurationDTO | null>(null);

  const dayOfWeekMap: { [key: string]: string } = {
    MONDAY: "Poniedziałek",
    TUESDAY: "Wtorek",
    WEDNESDAY: "Środa",
    THURSDAY: "Czwartek",
    FRIDAY: "Piątek",
    SATURDAY: "Sobota",
    SUNDAY: "Niedziela",
  };

  const handleTimeChange = (day: string, field: 'startTime' | 'endTime', value: string) => {
    setWorkingHoursConfig(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value
      }
    }));
  };


  const [selectedDays, setSelectedDays] = useState<SelectedDays>({
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
    SUNDAY: false,
  });

  const handleDayChange = (day: DayOfWeek) => {
    setSelectedDays((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };

  const sortDaysOfWeek = (days: string[]): string[] => {
    // Definiujemy kolejność dni
    const order = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];

    // Sortujemy tablicę dni zgodnie z ustaloną kolejnością
    return days.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  const translateDaysOfWeek = (days: string[]): string[] => {
    return days.map((day) => {
      // Asercja, że `day` jest kluczem w `dayOfWeekMap`
      const key = day as keyof typeof dayOfWeekMap;
      return dayOfWeekMap[key];
    });
  };

  const sortedAndTranslatedDays = () => {
    if (config !== null) {
      return translateDaysOfWeek(sortDaysOfWeek(config.availableDays));
    }
    return [];
  };

  const getBookingConfig = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      // console.log("Brak tokenu autoryzacyjnego");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/v1/config/getBookingConfig`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data: BookingConfigurationDTO = await response.json();
      setConfig(data);

      const updateBlockedDates = data.blockedDates.map((date) => ({
        date: date,
        block: true,
      }));

      const workingHoursFromServer = {
        MONDAY: data.workingHours.MONDAY,
        TUESDAY: data.workingHours.TUESDAY,
        WEDNESDAY: data.workingHours.WEDNESDAY,
        THURSDAY: data.workingHours.THURSDAY,
        FRIDAY: data.workingHours.FRIDAY,
        SATURDAY: data.workingHours.SATURDAY,
        SUNDAY: data.workingHours.SUNDAY
      };
      setWorkingHoursConfig(workingHoursFromServer);

      setBlockedDates(updateBlockedDates);
      setMonthsInAdvance(data.monthsInAdvance);
      // console.log("config: ", data);
    } catch (error) {
      console.error("Failed to fetch booking configuration:", error);
    }
  };

  const updateConfiguration = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFetching(true);

    const availableDays = Object.entries(selectedDays)
      .filter(([day, isSelected]) => isSelected)
      .map(([day]) => day);

    try {
      // Wywołaj funkcję aktualizującą konfigurację z nową wartością monthsInAdvance
      await updateMonthsInAdvance(monthsInAdvance);
      // console.log("Konfiguracja zaktualizowana");

      await updateBlockedDates(blockedDates);

      await setWorkingHours(workingHoursConfig);

      await getBookingConfig();

      await updateAvailableDays(availableDays);
    } catch (error) {
      console.error("Failed to update configuration", error);
    } finally {
      setIsFetching(false); // Zatrzymaj wskaźnik ładowania niezależnie od wyniku
    }

    setTimeout(() => {
      // console.log("Konfiguracja zaktualizowana");
      setIsFetching(false);
    }, 2000);
  };

  const setBookingsEnabled = async (enabled: boolean) => {
    if (!token) {
      console.error("No authorization token found.");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/v1/config/enableBookings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(enabled),
        }
      );

      if (response.ok) {
        // console.log("Bookings enabled status updated successfully.");
        // Możesz tutaj zaktualizować stan w swojej aplikacji, jeśli to konieczne
        getBookingConfig();
      } else {
        throw new Error("Failed to update bookings enabled status.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateMonthsInAdvance = async (months: number) => {
    try {
      const response = await fetch(
        `${apiUrl}/v1/config/monthsInAdvance?months=${months}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Dołącz token autoryzacji w nagłówkach
          },
        }
      );

      // console.log(months);

      if (response.ok) {
        const data = await response.text(); // Możesz użyć response.json() jeśli oczekujesz odpowiedzi JSON
        // console.log(data); // Logika po pomyślnej aktualizacji
      } else {
        // Obsługa różnych odpowiedzi HTTP niż 200
        const error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      console.error("Failed to update months in advance", error);
    }
  };

  const handleAddBlockedDay = () => {
    if (selectedDate && !blockedDates.some((bd) => bd.date === selectedDate)) {
      setBlockedDates((prevDates) => [
        ...prevDates,
        { date: selectedDate, block: true },
      ]);
    }
  };

  // Funkcja do usuwania (odblokowywania) daty
  const handleRemoveBlockedDay = (dateToRemove: string) => {
    setBlockedDates((prevDates) =>
      prevDates.map((bd) =>
        bd.date === dateToRemove ? { ...bd, block: false } : bd
      )
    );
  };

  const updateBlockedDates = async (
    blockedDates: { date: string; block: boolean }[]
  ) => {
    try {
      const response = await fetch(
        `${apiUrl}/v1/config/blockedDates`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(blockedDates),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update blocked dates");
      }

      // console.log("Blocked dates updated successfully");
    } catch (error) {
      console.error("Error updating blocked dates:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => {
      // Sprawdź, czy pole należy do zagnieżdżonego obiektu, na przykład "address.city"
      if (name.includes(".")) {
        const [parentKey, childKey] = name.split(".");
        return {
          ...prevUserData,
          [parentKey]: {
            // Zachowaj istniejące zagnieżdżone wartości
            ...(prevUserData as any)[parentKey],
            [childKey]: value,
          },
        };
      }
      // Dla niezagnieżdżonych właściwości, zachowaj dotychczasową logikę
      return {
        ...prevUserData,
        [name]: value,
      };
    });
  };

  const handleNavLinkClick = (sectionName: string) => {
    setDisplayedSection(sectionName);
    setActivLink(sectionName);
  };

  const changeUserData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!userData) return;

    // Przygotowanie danych do wysłania, bazując na aktualnym stanie userData
    const dataToSend = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      city: userData.address?.city || "",
      street: userData.address?.street || "",
      buildingNumber: userData.address?.buildingNumber || "",
      flatNumber: userData.address?.flatNumber || "",
    };

    const requestBody = JSON.stringify(dataToSend); // Przygotowanie ciała żądania do logowania
    // console.log(
    //   "Wysyłanie requestu do /api/v1/user/changeData z danymi:",
    //   requestBody
    // );

    try {
      const response = await fetch(
        `${apiUrl}/v1/user/changeData`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: requestBody,
        }
      );

      if (response.ok) {
        setShowSuccessMessage(true);
        setShowErrorMessage(false);
      } else {
        setShowSuccessMessage(false);
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.error("Wystąpił błąd:", error);
      alert("Wystąpił błąd podczas aktualizacji danych profilu");
    }
  };

  const setWorkingHours = async (workingHours: WorkingHoursConfig) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${apiUrl}/v1/config/setWorkingHours`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(workingHours),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      getBookingConfig()
      const data = await response.text();
      // console.log("Success:", data);
    } catch (error) {
      // console.log("Error during fetch:", error);
    }
  }

  const updateAvailableDays = async (availableDays: string[]) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${apiUrl}/v1/config/updateAvailableDays`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(availableDays),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      getBookingConfig();
      const data = await response.text();
      // console.log("Success:", data);
    } catch (error) {
      // console.error("Error during fetch:", error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserData(token).then((data) => {
        setUserData(data);
      });
    }
    getBookingConfig();
  }, [token]);

  useEffect(() => {
    if (config && config.availableDays.length) {
      const updatedSelectedDays = { ...selectedDays };
      Object.keys(updatedSelectedDays).forEach((day) => {
        updatedSelectedDays[day as DayOfWeek] =
          config.availableDays.includes(day);
      });
      setSelectedDays(updatedSelectedDays);
      // console.log("Config: ", config);
    }
  }, [config]); // Tylko dla config

  function formatTime(timeString: string) {
    const time = new Date("1970-01-01T" + timeString);
    return time.getHours() + ":" + time.getMinutes().toString().padStart(2, '0');
  }

  interface DaysMapping {
    [key: string]: string;
  }

  const daysMapping: DaysMapping = {
    MONDAY: 'Poniedziałek',
    TUESDAY: 'Wtorek',
    WEDNESDAY: 'Środa',
    THURSDAY: 'Czwartek',
    FRIDAY: 'Piątek',
    SATURDAY: 'Sobota',
    SUNDAY: 'Niedziela'
  };


  const orderedDays = ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];

  const dayNames = {
    MONDAY: 'Poniedziałek',
    TUESDAY: 'Wtorek',
    WEDNESDAY: 'Środa',
    THURSDAY: 'Czwartek',
    FRIDAY: 'Piątek',
    SATURDAY: 'Sobota',
    SUNDAY: 'Niedziela'
  };


  return (
    <>
      <div className={AccountMain.containerMain}>
        <div className={AccountMain.aboutSection}>
          <h1>Konto</h1>
        </div>
        <div className={AccountMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/konto">Konto</Link>
        </div>
      </div>
      <main>
        <section className={AccountMain.sectionContainer}>
          <div className={AccountMain.myAccount}>
            <h3>Witaj z powrotem {userData?.firstName}</h3>
            <ul className={AccountMain.myAccountNav}>
              <li>
                <a
                  className={
                    activLink === "reservation-config" ? AccountMain.active : ""
                  }
                  onClick={() => handleNavLinkClick("reservation-config")}
                >
                  Konfiguracja Rezerwacji
                </a>
              </li>
              <li>
                <a
                  className={
                    activLink === "allow-bookings" ? AccountMain.active : ""
                  }
                  onClick={() => handleNavLinkClick("allow-bookings")}
                >
                  Potwierdź rezerwację
                </a>
              </li>
              <li>
                <a
                  className={
                    activLink === "update-profile" ? AccountMain.active : ""
                  }
                  onClick={() => handleNavLinkClick("update-profile")}
                >
                  Zaktualizuj swój profil
                </a>
              </li>
              <li>
                <a
                  className={activLink === "messages" ? AccountMain.active : ""}
                  onClick={() => handleNavLinkClick("messages")}
                >
                  Wiadomości
                </a>
              </li>
            </ul>
            {displayedSection === "reservation-config" && (
              <div className={AdminPanelCSS.configurationSection}>
                <div className={AdminPanelCSS.card}>
                  <h2 className={AdminPanelCSS.cardHeader}>
                    Konfiguracja Rezerwacji
                  </h2>
                  <div className={AdminPanelCSS.cardBody}>
                    <div className={AdminPanelCSS.tabs}>
                      <button
                        className={`${AdminPanelCSS.tabButton} ${activeTab === "overview" ? AdminPanelCSS.active : ""
                          }`}
                        onClick={() => setActiveTab("overview")}
                      >
                        Podgląd
                      </button>
                      <button
                        className={`${AdminPanelCSS.tabButton} ${activeTab === "edit" ? AdminPanelCSS.active : ""
                          }`}
                        onClick={() => setActiveTab("edit")}
                      >
                        Modyfikacja
                      </button>
                    </div>
                    {activeTab === "overview" && (
                      <>
                        {config && (
                          <div className={AdminPanelCSS.tabContent}>
                            <div className={AdminPanelCSS.reservationInfo}>
                              <span
                                className={AdminPanelCSS.reservationInfoSpan}
                              >
                                Czy rezerwacje są włączone:{" "}
                              </span>{" "}
                              {config.bookingEnabled ? "Tak" : "Nie"}
                            </div>
                            {config.bookingEnabled && (
                              <>
                                <div className={AdminPanelCSS.reservationInfo}>
                                  <span
                                    className={
                                      AdminPanelCSS.reservationInfoSpan
                                    }
                                  >
                                    Ilość miesięcy na przód, które można
                                    rezerwować:
                                  </span>{" "}
                                  {config.monthsInAdvance}
                                </div>
                                <div className={AdminPanelCSS.reservationInfo}>
                                  <span
                                    className={
                                      AdminPanelCSS.reservationInfoSpan
                                    }
                                  >
                                    Zablokowane daty:
                                  </span>{" "}
                                  {config.blockedDates.map((date, index) => (
                                    <li key={index}>{date}</li>
                                  ))}
                                </div>
                                <div className={AdminPanelCSS.reservationInfo}>
                                  <span
                                    className={
                                      AdminPanelCSS.reservationInfoSpan
                                    }
                                  >
                                    Dostępne dni tygodnia:
                                  </span>
                                  <ul
                                    className={AdminPanelCSS.reservationInfoUl}
                                  >
                                    {sortedAndTranslatedDays().map(
                                      (day, index) => (
                                        <li
                                          className={
                                            index ===
                                              sortedAndTranslatedDays().length - 1
                                              ? AdminPanelCSS.reservationInfoLiLastChild
                                              : AdminPanelCSS.reservationInfoLi
                                          }
                                          key={index}
                                        >
                                          {day}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                                <div className={AdminPanelCSS.reservationInfo}>
                                  <span className={AdminPanelCSS.reservationInfoSpan}>
                                    Godziny pracy w dniach dostępnych:
                                  </span>
                                  <ul className={AdminPanelCSS.reservationInfoUl}>
                                    {orderedDays.map(day =>
                                      Object.entries(config.workingHours).filter(([key]) => daysMapping[key] === day).map(([key, hours]) =>
                                        <li key={key}>
                                          {daysMapping[key]}: zaczyna o {formatTime(hours.startTime)}, kończy o {formatTime(hours.endTime)}
                                        </li>
                                      )
                                    )}
                                  </ul>

                                </div>

                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {activeTab === "edit" && (
                      <div className={AdminPanelCSS.tabContent}>
                        <button className={AdminPanelCSS.enableButton} onClick={() => setBookingsEnabled(true)}>
                          Włącz rezerwacje
                        </button>
                        <button className={AdminPanelCSS.disableButton} onClick={() => setBookingsEnabled(false)}>
                          Wyłącz rezerwacje
                        </button>
                        {config?.bookingEnabled === true && (
                          <form
                            onSubmit={updateConfiguration}
                            className={AdminPanelCSS.form}
                          >
                            <div>
                              {Object.entries(selectedDays).map(
                                ([day, isSelected]) => (
                                  <div key={day}>
                                    <label>
                                      <input
                                        className={AdminPanelCSS.customCheckbox}
                                        type="checkbox"
                                        checked={isSelected}
                                        onChange={() => handleDayChange(day as DayOfWeek)}
                                      />
                                      {dayNames[day as keyof typeof dayNames]}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>


                            <div className={AdminPanelCSS.workingHoursContainer}>
                              {Object.entries(workingHoursConfig).map(([day, hours]) => (
                                <div key={day} className={AdminPanelCSS.formGroup}>
                                  <h4>{daysMapping[day]}</h4>
                                  <label className={AdminPanelCSS.label}>Godzina rozpoczęcia:</label>
                                  <input
                                    type="time"
                                    value={hours.startTime}
                                    onChange={e => handleTimeChange(day, 'startTime', e.target.value)}
                                    className={AdminPanelCSS.input}
                                  />
                                  <label className={AdminPanelCSS.label}>Godzina zakończenia:</label>
                                  <input
                                    type="time"
                                    value={hours.endTime}
                                    onChange={e => handleTimeChange(day, 'endTime', e.target.value)}
                                    className={AdminPanelCSS.input}
                                  />

                                </div>
                              ))}
                            </div>

                            <div className={AdminPanelCSS.formGroup}>
                              <label
                                htmlFor="monthsInAdvance"
                                className={AdminPanelCSS.label}
                              >
                                Ilość miesięcy na przód:
                              </label>
                              <input
                                type="number"
                                id="monthsInAdvance"
                                className={AdminPanelCSS.input}
                                value={monthsInAdvance}
                                onChange={(e) =>
                                  setMonthsInAdvance(Number(e.target.value))
                                }
                                min="1"
                                max="12"
                              />
                            </div>
                            <div>
                              <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) =>
                                  setSelectedDate(e.target.value)
                                }
                              />
                              <button
                                type="button"
                                onClick={handleAddBlockedDay}
                              >
                                Dodaj zablokowany dzień
                              </button>
                            </div>
                            <div>
                              <h4>Dni do zablokowania:</h4>
                              {blockedDates
                                .filter((bd) => bd.block)
                                .map((bd, index) => (
                                  <li key={index}>
                                    {bd.date}
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveBlockedDay(bd.date)
                                      }
                                    >
                                      Usuń
                                    </button>
                                  </li>
                                ))}
                            </div>
                            <button
                              type="submit"
                              className={AdminPanelCSS.submitButton}
                              disabled={isFetching}
                            >
                              {isFetching
                                ? "Aktualizowanie..."
                                : "Zaktualizuj Konfigurację"}
                            </button>
                          </form>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {displayedSection === "allow-bookings" && (
              <div>
                <BookingsToConfirm />
              </div>
            )}

            {displayedSection === "update-profile" && (
              <div className={AccountMain.updateProfile}>
                <form onSubmit={changeUserData}>
                  <div className={AccountMain.updateProfileRow}>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Imię</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="firstName"
                        value={userData ? userData.firstName : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Nazwisko</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="lastName"
                        value={userData ? userData.lastName : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Numer telefonu</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="phoneNumber"
                        value={userData ? userData.phoneNumber : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Miasto</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.city"
                        value={userData ? userData.address?.city : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Ulica</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.street"
                        value={userData ? userData.address?.street : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Numer domu/bloku</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.buildingNumber"
                        value={userData ? userData.address?.buildingNumber : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Number miszkania</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.flatNumber"
                        value={userData ? userData.address?.flatNumber : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}></div>
                    <div className={AccountMain.submitBtn}>
                      <input type="submit" value="Zaktualizuj dane" />
                    </div>
                    {showErrorMessage && (
                      <div className={AccountMain.alertProblem}>
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                          </svg>
                          Nie udało się zaktualicować
                        </p>
                      </div>
                    )}
                    {showSuccessMessage && (
                      <div className={AccountMain.alertSuccess}>
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                          </svg>
                          Zaktualizowano pomyślnie
                        </p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            )}

            {displayedSection === "messages" && <MessagesComponent />}
          </div>
        </section>
      </main>
    </>
  );
};
