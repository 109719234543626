import React from "react";
import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={OfferFirstMain.containerMain}>
        <div className={OfferFirstMain.aboutSection}>
          <h1>Ćwiczenia Indywidualne</h1>
        </div>
        <div className={OfferFirstMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
          <Link to="/ćwiczenia-indywidualne">Ćwiczenia Indywidualne</Link>
        </div>
      </div>

      <main className={OfferFirstMain.mainSection}>
        <div className={OfferFirstMain.mainDesc}>
          <div className={OfferFirstMain.textContent}>
            <p>
              <b>Ćwiczenia indywidualne</b> mają na celu poprawę kondycji fizycznej, redukcję bólu oraz przywrócenie sprawności.
              Ćwiczenia są dopasowane do stanu zdrowia, możliwości i celów każdej konkretnej osoby.
              Indywidualny charakter ćwiczeń pozwala precyzyjnie dopasować intensywność i rodzaj aktywności do potrzeb pacjenta, wspierając go w powrocie do pełni zdrowia.
            </p>
          </div>
        </div>
        <div className={OfferFirstMain.imgContainer}>
          <img
            src={require("./../../../images/Offerts/ćwiczenia-indywidualne/Zdjęcie12.jpg")}
            alt=""
          />
        </div>
        <div className={OfferFirstMain.row}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Jakie schorzenia leczymy?</h3>
            <span></span>
          </div>
          <ul>
            <li>Uszkodzenia mięśniowo-szkieletowe</li>
            <li>Choroby przewlekłe</li>
            <li>Problemy oddechowe</li>
            <li>Bóle mięśniowe</li>
            <li>Bóle stawowe</li>
            <li>Zaburzenia równowagi</li>
          </ul>
        </div>
        <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Korzyści terapii</h3>
            <span></span>
          </div>
          <ul>
            <li>Poprawa sprawności fizycznej</li>
            <li>Redukcja bólu</li>
            <li>Przywracanie mobilności</li>
            <li>Zapobieganie ponownym urazom</li>
            <li>Wsparcie w codziennym funkcjonowaniu</li>
          </ul>
        </div>
      </main>
    </>
  );
};
