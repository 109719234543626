import React, { useState } from "react";

// Definicja typów dla propsów
interface CustomTooltipProps {
  children: React.ReactNode;
  text: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, text }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    return (
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        style={{ position: "relative", display: "inline-block" }}
      >
        {children}
        {isVisible && (
          <div
            style={{
              position: "absolute",
              bottom: "100%",
              left: "50%",
              textAlign: "center",
              transform: "translateX(-50%)",
              backgroundColor: "#f9f9f9",
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
              width: "400px",
              whiteSpace: "normal", // Zmienione z 'nowrap' na 'normal' aby umożliwić zawijanie tekstu
              maxWidth: "350px", // Maksymalna szerokość tooltipa, która umożliwia zawijanie tekstu
              zIndex: 1000,
            }}
          >
            {text}
          </div>
        )}
      </div>
    );
  };
    

export default CustomTooltip;
