import React, { useState } from "react";

import LoginCSS from "../../styles/layouts/NavBarAndFooter/Login.module.css";
import { Link } from "react-router-dom";
import { faL, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface RegistrationFormProps {
  toggleVisibility: () => void;
  toggleLoginPopup: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  toggleVisibility,
  toggleLoginPopup,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isRegistered, setIsRegistered] = useState(false);

  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    address: {
      city: "",
      street: "",
      buildingNumber: "",
      flatNumber: "",
    },
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({
    firstName: { empty: false },
    lastName: { empty: false },
    phoneNumber: { empty: false },
    email: { empty: false, invalid: false },
    dateOfBirth: { empty: false },
    gender: { empty: false },
    city: { empty: false },
    street: { empty: false },
    buildingNumber: { empty: false },
    password: {
      empty: false,
      tooShort: false,
      capitalLetter: false,
      lowercase: false,
      digit: false
    },
    confirmPassword: { empty: false, notMatch: false }
  });

  const validateField = (name: string, value: string) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
      case 'city':
      case 'street':
        setFormErrors(prev => ({
          ...prev,
          [name]: {
            empty: !value.trim(),
          }
        }));
        break;

      case 'phoneNumber':
        setFormErrors(prev => ({
          ...prev,
          phoneNumber: {
            empty: !value.trim()
          }
        }));
        break;

      case 'email':
        setFormErrors(prev => ({
          ...prev,
          email: {
            empty: !value.trim(),
            invalid: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          }
        }));
        break;

      case 'dateOfBirth':
        setFormErrors(prev => ({
          ...prev,
          dateOfBirth: {
            empty: !value.trim()
          }
        }));
        break;

      case 'gender':
        setFormErrors(prev => ({
          ...prev,
          gender: {
            empty: !value
          }
        }));
        break;

      case 'buildingNumber':
        setFormErrors(prev => ({
          ...prev,
          [name]: {
            empty: !value.trim(),
          }
        }));
        break;

      case 'password':
        const tooShort = value.length >= 8;
        const capitalLetter = /[A-Z]/.test(value); // Check for at least one capital letter
        const lowercase = /[a-z]/.test(value); // Check for at least one lowercase letter
        const digit = /\d/.test(value); // Check for at least one digit
        setFormErrors(prev => ({
          ...prev,
          password: {
            empty: !value.trim(),
            tooShort: !tooShort,
            capitalLetter: !capitalLetter,
            lowercase: !lowercase,
            digit: !digit,
          },
        }));
        break;

      case 'confirmPassword':
        setFormErrors(prev => ({
          ...prev,
          confirmPassword: {
            empty: !value.trim(),
            notMatch: value !== registrationData.password
          }
        }));
        break;
    }
  };

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return "Hasło musi zawierać co najmniej 8 znaków";
    }
    if (!/[A-Z]/.test(password)) {
      return "Hasło musi zawierać co najmniej jedną wielką literę";
    }
    if (!/[a-z]/.test(password)) {
      return "Hasło musi zawierać co najmniej jedną małą literę";
    }
    if (!/\d/.test(password)) {
      return "Hasło musi zawierać co najmniej jedną liczbę";
    }
    return null; // Hasło spełnia wszystkie wymagania
  };


  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    validateField(name, value);
  };


  const handleRegistrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const filteredValue = name === 'phoneNumber' ? value.replace(/\D/g, '') : value;

    if (name in registrationData.address) {
      setRegistrationData(prevState => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: filteredValue
        }
      }));
    } else {
      setRegistrationData(prevState => ({
        ...prevState,
        [name]: filteredValue
      }));
      validateField(name, filteredValue);
    }
  };


  const validateAllFields = () => {
    // Manually trigger validations for all fields
    validateField('firstName', registrationData.firstName);
    validateField('lastName', registrationData.lastName);
    validateField('phoneNumber', registrationData.phoneNumber);
    validateField('email', registrationData.email);
    validateField('dateOfBirth', registrationData.dateOfBirth);
    validateField('gender', registrationData.gender);
    validateField('city', registrationData.address.city);
    validateField('street', registrationData.address.street);
    validateField('buildingNumber', registrationData.address.buildingNumber);
    validateField('flatNumber', registrationData.address.flatNumber);
    validateField('password', registrationData.password);
    validateField('confirmPassword', registrationData.confirmPassword);

    // Check if there are any errors in the formErrors state after validation
    return !Object.values(formErrors).some(errorFields =>
      Object.values(errorFields).some(error => error)
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const allFieldsValid = validateAllFields();

    if (!allFieldsValid) {
      setErrorMessage("Proszę poprawić błędy w formularzu.");
      return;
    }

    const isAllFieldsFilled = Object.entries(registrationData).every(
      ([key, value]) => {

        if (typeof value === "object" && value !== null) {
          // Pominięcie flatNumber przy sprawdzaniu
          return Object.entries(value).every(([innerKey, innerValue]) => {
            if (innerKey === 'flatNumber') {
              return true;
            }
            return innerValue.trim() !== "";
          });
        }
        return value.trim() !== "";
      }
    );
    if (!isAllFieldsFilled) {
      setErrorMessage("Wszystkie pola muszą być wypełnione.");
      return;
    } else {
      setErrorMessage("");
    }

    const passwordError = validatePassword(registrationData.password);
    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }

    if (registrationData.password !== registrationData.confirmPassword) {
      setErrorMessage("Hasła nie są zgodne.");
      return;
    } else {
      setErrorMessage("");
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `${apiUrl}/v1/registration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(registrationData),
        }
      );

      if (response.ok) {
        setIsRegistered(true);
        setErrorMessage("");
      } else {
        if (response.status === 409) {
          const errorData = await response.json();
          // console.log("errorData", errorData)
          setErrorMessage(errorData.message);
        } else {
          const errorMessage = await response.text();
          console.error('Something went wrong:', errorMessage);
        }

      }
    } catch (error) {
      setErrorMessage("Nie udało się połączyć z serwerem.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={LoginCSS.loginPopup}>
      <div className={LoginCSS.overlay}></div>

      <div className={LoginCSS.popupContent}>
        <div className={LoginCSS.loginBox}>
          <div className={LoginCSS.LoginBoxTextContent}>
            <h2>Zaloguj się</h2>
            <p>Kliknij przycisk Zaloguj się, aby zalogować się na konto.</p>
            <button onClick={toggleVisibility}>Zaloguj się</button>
          </div>
        </div>
        <div className={LoginCSS.formContainer}>
          <h2>Utwórz nowe konto</h2>
          {isRegistered ? (
            <div>
              <p>
                Na Twój adres email wysłano link potwierdzający. Prosimy o
                potwierdzenie rejestracji w ciągu 2 godzin.
              </p>
              <p>Jeżeli nie możesz znaleźć maila zajrzyj do spamu</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={LoginCSS.loginForm}>
              <div className={LoginCSS.loginFormValues}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Imię"
                  value={registrationData.firstName}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.firstName.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.firstName.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj Imię</p>
                )}
                <input
                  type="text"
                  name="lastName"
                  placeholder="Nazwisko"
                  value={registrationData.lastName}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.lastName.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.lastName.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj Nazwisko</p>
                )}
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Numer telefonu"
                  value={registrationData.phoneNumber}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.phoneNumber.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                  pattern="[0-9]*"
                />
                {formErrors.phoneNumber.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj numer telefonu komórkowego</p>
                )}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={registrationData.email}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.email.empty || formErrors.email.invalid ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.email.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj adres email</p>
                )}
                {formErrors.email.invalid && (
                  <p className={LoginCSS.errorMessage}>Nieprawidłowy adres email</p>
                )}
                <input
                  type="date"
                  name="dateOfBirth"
                  value={registrationData.dateOfBirth}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.dateOfBirth.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.dateOfBirth.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj datę urodzenia</p>
                )}
              </div>
              <div className={LoginCSS.loginFormGender}>
                <label htmlFor="female">Pani</label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="FEMALE"
                  checked={registrationData.gender === "FEMALE"}
                  onChange={handleRegistrationChange}
                />
                <label htmlFor="female">Pan</label>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="MALE"
                  checked={registrationData.gender === "MALE"}
                  onChange={handleRegistrationChange}
                />
              </div>
              {formErrors.gender.empty && (
                <p className={LoginCSS.errorMessage}>Proszę wybrać płeć</p>
              )}
              <div className={LoginCSS.loginFormValues}>
                <input
                  type="text"
                  name="city"
                  placeholder="Miasto"
                  value={registrationData.address.city}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.city.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.city.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj miasto</p>
                )}
                <input
                  type="text"
                  name="street"
                  placeholder="Ulica"
                  value={registrationData.address.street}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.street.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.street.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj ulicę</p>
                )}
                <input
                  type="text"
                  name="buildingNumber"
                  placeholder="Numer Budynku"
                  value={registrationData.address.buildingNumber}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.buildingNumber.empty ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.buildingNumber.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj numer budynku</p>
                )}
                <input
                  type="number"
                  name="flatNumber"
                  placeholder="Numer Mieszkania"
                  value={registrationData.address.flatNumber}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={LoginCSS.normalInput}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Hasło"
                  value={registrationData.password}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={(formErrors.password.empty || formErrors.password.tooShort || formErrors.password.capitalLetter || formErrors.password.lowercase || formErrors.password.digit) ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.password.empty && (
                  <p className={LoginCSS.errorMessage}>Podaj hasło</p>
                )}
                {formErrors.password.tooShort && (
                  <p className={LoginCSS.errorMessage}>Min. 8 znaków</p>
                )}
                {formErrors.password.capitalLetter && (
                  <p className={LoginCSS.errorMessage}>wielka litera</p>
                )}
                {formErrors.password.lowercase && (
                  <p className={LoginCSS.errorMessage}>mała litera</p>
                )}
                {formErrors.password.digit && (
                  <p className={LoginCSS.errorMessage}>cyfra</p>
                )}
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Powtórz hasło"
                  value={registrationData.confirmPassword}
                  onChange={handleRegistrationChange}
                  onBlur={handleBlur}
                  className={formErrors.confirmPassword.empty || formErrors.confirmPassword.notMatch ? LoginCSS.errorInput : LoginCSS.normalInput}
                />
                {formErrors.confirmPassword.empty && (
                  <p className={LoginCSS.errorMessage}>Potwierdź hasło</p>
                )}
                {formErrors.confirmPassword.notMatch && (
                  <p className={LoginCSS.errorMessage}>Hasła nie są identyczne</p>
                )}
                <div className={LoginCSS.privatePolicy}>
                  <input
                    type="checkbox"
                    name="confirmPolicy"
                    id="confirmPolicy"
                    required
                  />
                  <label><span>*</span> Zapoznałem się z <Link to="/regulamin-swiadczenia-uslug-droga-elektroniczna">regulaminem strony</Link> oraz z <Link to="/polityka-prywatności">polityków prywatności</Link> i akceptuję jego treść</label>
                </div>
              </div>
              {errorMessage && (
                <p className={LoginCSS.errorMessage}>{errorMessage}</p>
              )}
              <button type="submit">
                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Zarejestruj'}
              </button>
            </form>
          )}
        </div>
        <button className={LoginCSS.closeBtn} onClick={toggleLoginPopup}>
          <span>x</span>
        </button>
      </div>
    </div >
  );
};

export default RegistrationForm;
