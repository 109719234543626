import React from "react";
import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={OfferFirstMain.containerMain}>
        <div className={OfferFirstMain.aboutSection}>
          <h1>Terapia Tkanek Miękkich</h1>
        </div>
        <div className={OfferFirstMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
          <Link to="/terapia-tkanek-miękkich">
            Terapia Stawów Skroniowo Żuchwowych Crafta
          </Link>
        </div>
      </div>

      <main className={OfferFirstMain.mainSection}>
        <div className={OfferFirstMain.mainDesc}>
          <div className={OfferFirstMain.textContent}>
            <p>
              <b>Terapia tkanek miękkich</b> to kompleksowy zestaw technik wykorzystywanych do leczenia dysfunkcji i urazów tkanek miękkich,
              takich jak mięśnie, powięzie czy ścięgna. Ta forma terapii obejmuje różne metody, w tym stretching, techniki mobilizacji tkanek miękkich.
              Jest często wykorzystywana w sporcie do regeneracji i zapobiegania urazom.
            </p>
          </div>
        </div>
        <div className={OfferFirstMain.imgContainer}>
          <img
            src={require("./../../../images/Offerts/terapia-tkanek-miękkich/Zdjęcie8.jpg")}
            alt=""
          />
        </div>
        <div className={OfferFirstMain.row}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Jakie schorzenia leczymy?</h3>
            <span></span>
          </div>
          <ul>
            <li>Bóle kręgosłupa</li>
            <li>Napięcia mięśniowe</li>
            <li>Zespoły bólowe</li>
            <li>Uszkodzenia ścięgien czy więzadeł</li>
            <li>Rehabilitacja po urazach sportowych</li>
            <li>Przewlekłe problemy z ruchem</li>
            <li>Bóle mięśniowe związane z aktywnością fizyczną</li>
          </ul>
        </div>
        <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Korzyści terapii</h3>
            <span></span>
          </div>
          <ul>
            <li>Łagodzi ból</li>
            <li>Poprawia elastyczność i zakres ruchu</li>
            <li>Wspomaga gojenie się urazów</li>
            <li>Pomaga w zapobieganiu urazom</li>
          </ul>
        </div>
      </main>
    </>
  );
};
