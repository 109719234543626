import React, { useEffect, useState } from "react";
import AccountMain from "./../../../styles/layouts/Account/components/Main.module.css";
import ConfirmDialog from "./ConfirmDialog";
import { Link } from "react-router-dom";
import { error } from "console";

interface Booking {
  id: number;
  bookingDate: string;
  message: string;
  confirmed: boolean;
}

const ScheduledBookings: React.FC<{ userId: number | undefined }> = ({ userId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<number | null>(
    null
  );

  const token = localStorage.getItem("token");

  const cancelBooking = async (bookingId: number, initiator: 'USER_ROLE' | 'ADMIN_ROLE') => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${apiUrl}/v1/booking/cancel/${bookingId}/${initiator}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setBookings((currentBookings) =>
          currentBookings.filter((booking) => booking.id !== bookingId)
        );
        // console.log("Booking cancelled successfully");
      } else {
        const errorText = await response.text(); 
        console.error("Failed to cancel booking:", errorText);
        alert(errorText);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: "UTC" // Ustawienie strefy czasowej na UTC
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC" // Ustawienie strefy czasowej na UTC
    };
    return `${date.toLocaleDateString(
      "pl-GB",
      optionsDate
    )} ${date.toLocaleTimeString("pl-GB", optionsTime)}`;
  };

  const promptCancelBooking = (bookingId: number | null) => {
    setSelectedBookingId(bookingId);
    setIsDialogOpen(true);
  };

  const handleConfirmCancel = () => {
    // Sprawdź, czy selectedBookingId nie jest null
    if (selectedBookingId !== null) {
      cancelBooking(selectedBookingId, "USER_ROLE");
      setIsDialogOpen(false);
      // Usuń anulowaną rezerwację ze stanu
      setBookings((currentBookings) =>
        currentBookings.filter((booking) => booking.id !== selectedBookingId)
      );
    }
  };

  const handleCancelDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const fetchScheduledBookings = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/v1/booking/getScheduleBookingByUser/${userId}`,
          // `http://localhost:8080/api/v1/booking/getScheduleBookingByUser/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        if (response.status === 204) {
          setBookings([]);
          return;
        }
        const data: Booking[] = await response.json();
        setBookings(data);
      } catch (error) {
        console.error("Failed to fetch scheduled bookings:", error);
      }
    };

    // console.log(userId);
    fetchScheduledBookings();
  }, [userId, token]);

  return (
    <>
      {bookings.length > 0 ? (
        <div className={AccountMain.nextReservation}>
          <table className={AccountMain.appointmentTable}>
            <thead>
              <tr>
                <td>Kiedy</td>
                <td>Cena</td>
                <td>Status</td>
                <td>Działanie</td>
              </tr>
            </thead>
            <tbody>
              {bookings.map((bookings) => (
                <tr key={bookings.id}>
                  <td>
                    <div> {formatDate(bookings.bookingDate)} </div>
                  </td>
                  <td>200 zł</td>
                  <td>
                    {" "}
                    {bookings.confirmed
                      ? "Potwierdzono"
                      : "Nie potwierdzono"}{" "}
                  </td>
                  <td>
                    <button onClick={() => promptCancelBooking(bookings.id)}>
                      Anuluj Wizytę
                    </button>
                    <ConfirmDialog
                      isOpen={isDialogOpen}
                      message="Czy na pewno chcesz anulować tę wizytę?"
                      onConfirm={handleConfirmCancel}
                      onCancel={handleCancelDialog}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={AccountMain.nextReservationBtn}>
            <Link to="/zarezerwuj">ZAREZERWUJ</Link>
          </div>
        </div>
      ) : (
        <div className={AccountMain.nextReservation}>
          <p>Nie masz nadchodzących rezerwacji.</p>
          <div className={AccountMain.nextReservationBtn}>
            <Link to="/zarezerwuj">ZAREZERWUJ</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduledBookings;
