import React from "react";
import InformationsSectionCSS from "./../../../styles/layouts/HomePage/components/InformationsSection.module.css";
import { Link } from "react-router-dom";

export const InformationsSection = () => {
  return (
    <section className={InformationsSectionCSS.informationsSection}>
      <div className={InformationsSectionCSS.informationsContainer}>
        <div className={InformationsSectionCSS.informations}>
          <Link to="/pierwsza-wizyta">
            <img
              src={require("./../../../images/HomePage/InformationSection/number-one.png")}
              alt="number-one"
            />
          </Link>
          <h4>PIERWSZA WIZYTA</h4>
          <p>Jak się przygotować?</p>
          <Link
            to="/pierwsza-wizyta"
            className={InformationsSectionCSS.hiddenLink}
          >
            SPRAWDŹ
          </Link>
        </div>
        <div className={InformationsSectionCSS.informations}>
          <Link to="/faq">
            <img
              src={require("./../../../images/HomePage/InformationSection/question-mark.png")}
              alt="number-one"
            />
          </Link>
          <h4>NAJCZĘSCIEJ ZADAWANE PYTANIA</h4>
          <p>Poznaj szczegóły</p>
          <Link to="/faq" className={InformationsSectionCSS.hiddenLink}>
            SPRAWDŹ
          </Link>
        </div>
        <div className={InformationsSectionCSS.informations}>
          <Link to="/cennik">
            <img
              src={require("./../../../images/HomePage/InformationSection/wallet.png")}
              alt="number-one"
            />
          </Link>
          <h4>CENNIK</h4>
          <p>Sprawdź ofertę</p>
          <Link to="/cennik" className={InformationsSectionCSS.hiddenLink}>
            SPRAWDŹ
          </Link>
        </div>
        <div className={InformationsSectionCSS.informations}>
          <Link to="/kontakt">
            <img
              src={require("./../../../images/HomePage/InformationSection/localization.png")}
              alt="number-one"
            />
          </Link>
          <h4>KONTAKT</h4>
          <p>Napisz do mnie</p>
          <Link to="/kontakt" className={InformationsSectionCSS.hiddenLink}>
            SPRAWDŹ
          </Link>
        </div>
      </div>
    </section>
  );
};
