import React from "react";
import { HomePage } from "./layouts/HomePage/HomePage";
import { AboutMe } from "./layouts/AboutMe/AboutMe";
import { PriceList } from "./layouts/PriceList/PriceList";
import { Offer } from "./layouts/Offer/Offer";
import { Contact } from "./layouts/contact/Contact";
import { Questions } from "./layouts/Questions/Questions";
import { Book } from "./layouts/Book/Book";
import { Route, Switch } from "react-router-dom";
import { Navbar } from "./layouts/NavBarAndFooter/NavBar";
import { Header } from "./layouts/HomePage/components/Header";
import { FirstVisit } from "./layouts/FirstVisit/FirstVisit";
import { OfferFirst } from "./layouts/OfferFirst/OfferFirst";
import { OfferSecond } from "./layouts/OfferSecond/OfferSecond";
import { OfferThird } from "./layouts/OfferThird/OfferThird";
import { OfferFourth } from "./layouts/OfferFourth/OfferFourth";
import { OfferFifth } from "./layouts/OfferFifth/OfferFifth";
import { OfferSixth } from "./layouts/OfferSixth/OfferSixth";
import { OfferSeventh } from "./layouts/OfferSeventh/OfferSeventh";
import { Footer } from "./layouts/NavBarAndFooter/Footer";
import { Main } from "./layouts/Account/components/UserPanel/Main";
import { AdminPanel } from "./layouts/Account/components/AdminPanel/AdminPanel";
import { PrivateRoute } from "./utils/PrivateRoute";
import { RegulationOne } from "./layouts/Regulations/RegulationOne";
import CookieConsent from "./layouts/CookieConsent/CookieConsent";
import { PrivacyPolicy } from "./layouts/Regulations/PrivacyPolicy";
import ScrollToTop from "./utils/ScrollToTop";
import useAuth from "./utils/useAuth";
import { ConfirmRegistration } from "./layouts/ConfirmRegistration/ConfirmRegistration";

export const App = () => {
  useAuth();

  return (
    <div>
      <Header />
      <Navbar />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/o-mnie">
          <AboutMe />
        </Route>
        <Route path="/cennik">
          <PriceList />
        </Route>
        <Route path="/pierwsza-wizyta">
          <FirstVisit />
        </Route>
        <Route path="/oferta">
          <Offer />
        </Route>
        <Route path="/faq">
          <Questions />
        </Route>
        <Route path="/kontakt">
          <Contact />
        </Route>
        <Route path="/zarezerwuj">
          <Book />
        </Route>
        <PrivateRoute
          path="/konto/uzytkownik"
          component={Main}
          roles={["USER_ROLE"]}
        ></PrivateRoute>
        <PrivateRoute
          path="/konto/admin"
          component={AdminPanel}
          roles={["ADMIN_ROLE"]}
        ></PrivateRoute>
        <Route path="/terapia-manualna-wg-koncepcji-maitlanda">
          <OfferFirst />
        </Route>
        <Route path="/terapia-stawów-skroniowo-żuchwowych-crafta">
          <OfferSecond />
        </Route>
        <Route path="/terapia-tkanek-miękkich">
          <OfferThird />
        </Route>
        <Route path="/rehabilitacja-po-operacyjna">
          <OfferFourth />
        </Route>
        <Route path="/rehabilitacja-seniorów">
          <OfferFifth />
        </Route>
        <Route path="/masaż">
          <OfferSixth />
        </Route>
        <Route path="/ćwiczenia-indywidualne">
          <OfferSeventh />
        </Route>
        <Route path="/regulamin-organizacyjny">
          <RegulationOne />
        </Route>
        <Route path="/polityka-prywatności">
          <PrivacyPolicy />
        </Route>
        <Route path="/potwierdzenie-rejestracji">
          <ConfirmRegistration />
        </Route>
      </Switch>
      <Footer />
      <CookieConsent />
    </div>
  );
};
