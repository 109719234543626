import AddressModel from "./AddressModel";
import GenderModel from "./GenderModel";
import UserRoleModel from "./UserRoleModel";

class AppUserModle {
  id?: number;
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: Date;

  gender?: GenderModel;
  address?: AddressModel;
  userRole?: UserRoleModel;

  locked?: boolean;
  enabled?: boolean;

  constructor(
    id: number | undefined,
    firstName: string,
    lastName: string,
    password: string,
    email: string,
    phoneNumber: string,
    dateOfBirth: Date,
    gender: GenderModel,
    address: AddressModel,
    userRole: UserRoleModel,
    locked: boolean,
    enabled: boolean
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.password = password;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.address = address;
    this.userRole = userRole;
    this.locked = locked;
    this.enabled = enabled;
  }
}

export default AppUserModle;
