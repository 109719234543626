import React from "react";
import styles from "./../../styles/layouts/Regulations/PrivacyPolicy.module.css";

export const PrivacyPolicy = () => {
    return (
        <div className={styles.privacyPolicyContainer}>
            <h1>Polityka Prywatności</h1>
            <h2>I. Postanowienia ogólne</h2>
            <ol>
                <li>Niniejsza Polityka ochrony prywatności określa sposób zbierania, przetwarzania i przechowywania danych osobowych koniecznych do świadczenia usług drogą elektroniczną za pośrednictwem serwisu internetowego w domenie fizjocezar.pl.</li>
                <li>Administratorem danych osobowych Użytkowników jest Cezary Ciecierski.</li>
                <li>Dane osobowe przetwarzane są zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).</li>
                <li>Zbierane przez Administratora dane będą:
                    <ul>
                        <li>przetwarzane zgodnie z prawem,</li>
                        <li>przetwarzane w wyraźnie określonych celach i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,</li>
                        <li>merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane,</li>
                        <li>przechowywane nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.</li>
                    </ul>
                </li>
            </ol>
            <h2>II. Cel i podstawy prawne przetwarzania danych</h2>
            <ol>
                <li>Administrator przetwarza dane osobowe niezbędne do świadczenia i rozwoju oferowanych usług dostępnych za pośrednictwem Serwisu oraz poszczególnych jego funkcjonalności.</li>
                <li>Dane osobowe przetwarzane będą w następujących celach:
                    <ol type="a">
                        <li>rejestracji konta, weryfikacji tożsamości Użytkownika oraz realizacji umowy o świadczeniu usług drogą elektroniczną zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, w tym w szczególności poprzez zapewnienie możliwości korzystania z konta Użytkownika – na podstawie akceptacji warunków Regulaminu (art. 6 ust. 1 lit. b RODO);</li>
                        <li>komunikacji z Użytkownikiem w celu dostarczenia mu niezbędnych informacji oraz budowania pozytywnych i rzetelnych relacji z Nim, co stanowi prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO);</li>
                        <li>promowania przez Administratora produktów i/lub usług własnych oraz swoich Partnerów poprzez kierowanie drogą elektroniczną Informacji marketingowych (newsletter) o ile Użytkownik wyraził zgodę na otrzymywanie takich powiadomień za pośrednictwem e-mail (art. 6 ust. 1 lit. a RODO);</li>
                        <li>udzielenia dostępu do informacji o nowościach z branży bezpośrednio powiązanej z działalnością Administratora, o ile Użytkownik wyraził zgodę na otrzymywanie takich powiadomień za pośrednictwem e-mail (art. 6 ust. 1 lit. a RODO),</li>
                        <li>w celach analitycznych i statystycznych na podstawie prawnie uzasadnionego interesu Administratora polegającego na prowadzeniu weryfikacji aktywności Użytkowników i ich preferencji dla optymalizacji usług i produktów oraz stosowanych funkcjonalności Serwisu (art. 6 ust. 1 lit. f RODO);</li>
                        <li>ewentualnego ustalenia, dochodzenia roszczeń lub obrony przed nimi na podstawie prawnie uzasadnionego interesu Administratora polegającego na ochronie jej praw (art. 6 ust. 1 lit. f RODO).</li>
                    </ol>
                </li>
            </ol>
            <h2>III. Okres przetwarzania danych osobowych</h2>
            <ol>
                <li>Dane osobowe będą przetwarzane przez okres, w którym osoba pozostaje aktywnym Użytkownikiem Serwisu (posiada konto Użytkownika), a po tym czasie przez okres niezbędny do zachowania zgodności z przepisami prawa, dochodzenia lub obrony przed ewentualnymi roszczeniami, jednak nie dłużej niż 3 lata liczone od dnia rozwiązania umowy o świadczeniu usług drogą elektroniczną.</li>
                <li>Dane przetwarzane na podstawie zgody będą przetwarzane do czasu wycofania udzielonej zgody z zastrzeżeniem, że wycofanie tej zgody nie ma wpływu na zgodność przetwarzania danych, którego dokonano przed tym wycofaniem.</li>
            </ol>
            <h2>IV. Informacje o przetwarzaniu</h2>
            <ol>
                <li>Dane osobowe w zależności od celu przetwarzania mogą zostać ujawnione:
                    <ol type="a">
                        <li>podmiotom powiązanym z Administratorem</li>
                        <li>podmiotom współpracującym z Administratorem,</li>
                        <li>podwykonawcom, w szczególności podmiotom dostarczającym i obsługującym wybrane systemy i rozwiązania informatyczne,</li>
                        <li>podmiotom obsługującym płatności internetowe,</li>
                        <li>podmiotom świadczącym usługi kuriersko-pocztowe,</li>
                        <li>kancelariom prawnym.</li>
                    </ol>
                </li>
                <li>Dane osobowe przetwarzane przez Administratorem, nie będą/będą przekazywane poza Europejski Obszar Gospodarczy ani do organizacji międzynarodowych.</li>
            </ol>
            <h2>V. Prawa osób, których dane dotyczą</h2>
            <ol>
                <li>Użytkownik Serwisu ma prawo:
                    <ul>
                        <li>dostępu do treści swoich danych osobowych</li>
                        <li>sprostowania danych</li>
                        <li>usunięcia danych</li>
                        <li>ograniczenia przetwarzania danych</li>
                        <li>przenoszenia danych</li>
                        <li>wniesienia sprzeciwu wobec przetwarzania odbywającego się na podstawie prawienie usprawiedliwionego interesu administratora</li>
                        <li>wycofania zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie tej zgody przed jej wycofaniem</li>
                    </ul>
                </li>
                <li>Użytkownik ma prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych w sytuacji, w której uzna, że przetwarzanie narusza jego prawa i wolności.</li>
                <li>W procesie przetwarzania danych nie dochodzi do zautomatyzowanego podejmowania decyzji w tym do profilowania.</li>
            </ol>
            <h2>VI. Postanowienia końcowe</h2>
            <ol>
                <li>Administrator zastrzega sobie prawo do wprowadzenia zmian w niniejszej Polityce prywatności jednocześnie zapewnia, że prawa Użytkowników wynikające z niniejszego dokumenty nie zostaną ograniczone.</li>
                <li>O wszelkich zmiana w Polityce prywatności Użytkownik zostanie poinformowany poprzez komunikat dostępny w Serwisie.</li>
            </ol>
        </div>
    );
}