import React from "react";
import AboutMeMain from "./../../../styles/layouts/AboutMe/components/Main.module.css";
import { Link } from "react-router-dom";
export const Main = () => {
  return (
    <>
      <div className={AboutMeMain.containerMain}>
        <div className={AboutMeMain.aboutSection}>
          <h1>O mnie</h1>
        </div>
        <div className={AboutMeMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/o-mnie">O mnie</Link>
        </div>
      </div>
      <main>
        <section className={AboutMeMain.mainSection}>
          <div className={AboutMeMain.textContent}>
            <p>
              Witaj! Nazywam się Cezary Ciecierski, jestem magistrem fizjoterapii oraz dyplomowanym technikiem masażystą.
              Oferuję rehabilitację z dojazdem do domu. Specjalizuję się w pracy z pacjentami z dolegliwościami bólowymi,
              fizjoterapii pooperacyjnej. Zajmuję się też seniorami, którzy wymagają usprawnienia.
            </p>
            <p>
              W mojej pracy wykorzystuję wiedzę zdobytą na kursach specjalistycznych z zakresu terapii manualnej wg Koncepcji Maitland,
              pracuje też Metodą Crafta, która pomaga w pacjentom z dolegliwościami stawów skroniowo-żuchwowych.
              Istotną rolą w procesie rehabilitacji są ćwiczenia, które dostosowuję indywidualnie do każdego pacjenta.
            </p>
            <p>
              Serdecznie zapraszam!
            </p>
          </div>
          <div className={AboutMeMain.imageContent}>
            <img src={require("../../../images/AboutMe/Zdjęcie2.jpg")} alt="mail" />
          </div>
        </section>
      </main>
    </>
  );
};
