import React from "react";
import RehabBenefitsSectionCSS from "./../../../styles/layouts/HomePage/components/RehabBenefitsSection.module.css";

export const RehabBenefitsSection = () => {
  return (
    <section className={RehabBenefitsSectionCSS.rehabBenefitsSection}>
      <div className={RehabBenefitsSectionCSS.sectionTitle}>
        <h1>Dlaczego rehabilitacja domowa?</h1>
        <span></span>
      </div>
      <div className={RehabBenefitsSectionCSS.rehabBenefitsContainer}>
        <div className={RehabBenefitsSectionCSS.rehabBenefits}>
          <h4>Oszczędność czasu</h4>
          <p>
            Nie tracisz czasu na dojazd do gabinetu na wizytę fizjoterapeutyczną
          </p>
        </div>
        <div className={RehabBenefitsSectionCSS.rehabBenefits}>
          <h4>Zmniejszenie stresu</h4>
          <p>Znika problem „Kto dowiezie na rehabilitację po operacji”</p>
        </div>
        <div className={RehabBenefitsSectionCSS.rehabBenefits}>
          <h4>Prywatność</h4>
          <p>Możliwość pracy 1 na 1 z fizjoterapeutą</p>
        </div>
        <div className={RehabBenefitsSectionCSS.rehabBenefits}>
          <h4>Komfortowa rehabilitacja</h4>
          <p>Profesjonalna terapia w zaciszu domowym</p>
        </div>
      </div>
    </section>
  );
};
