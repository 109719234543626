import React from "react";

import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";
import { Offer } from "../../Offer/Offer";

export const Main = () => {
  return (
    <>
      <div className={OfferFirstMain.containerMain}>
        <div className={OfferFirstMain.aboutSection}>
          <h1>Terapia manualna według koncepcji Maitland </h1>
        </div>
        <div className={OfferFirstMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
          <Link to="/terapia-manualna-wg-koncepcji-maitlanda">
            Terapia manualna według koncepcji Maitland
          </Link>
        </div>
      </div>
      <main className={OfferFirstMain.mainSection}>
        <div className={OfferFirstMain.mainDesc}>
          <div className={OfferFirstMain.textContent}>
            <p>
              <b>Terapia manualna według koncepcji Maitland</b>
            </p>
            <p>
              Metoda opiera się na kompleksowej ocenie pacjenta, wykorzystując techniki manualne w celu redukcji bólu,
              przywrócenia prawidłowego zakresu ruchu. Koncepcja zakłada indywidualne podejście do pacjenta,
              uwzględniając jego specyficzne potrzeby i reakcje organizmu na terapię.
            </p>
          </div>
        </div>
        <div className={OfferFirstMain.imgContainer}>
          <img
            src={require("./../../../images/Offerts/terapia-manualna-wg-koncepcji-maitlanda/Zdjęcie6.jpg")}
            alt=""
          />
        </div>
        <div className={OfferFirstMain.row}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Jakie schorzenia leczymy?</h3>
            <span></span>
          </div>
          <ul>
            <li>Bóle kręgosłupa</li>
            <li>Bole stawowe</li>
            <li>Zaburzenia mięśniowo-szkieletowe</li>
            <li>Zaburzenia nerwowe</li>
            <li>Zespół bólowy</li>
            <li>Usprawnianie po urazach</li>
          </ul>
        </div>
        <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Korzyści terapii</h3>
            <span></span>
          </div>
          <ul>
            <li>Redukcja bólu</li>
            <li>Poprawa zakresu ruchomości</li>
            <li>Zmniejszenie napięcia mięśniowego</li>
            <li>Poprawa funkcji codziennych</li>
            <li>Poprawa samopoczucia</li>
          </ul>
        </div>
      </main>
    </>
  );
};
