import React from "react";
import OfferMain from "./../../../styles/layouts/Offer/components/Main.module.css";
import { Link } from "react-router-dom";
export const Main = () => {
  return (
    <>
      <div className={OfferMain.containerMain}>
        <div className={OfferMain.aboutSection}>
          <h1>Oferta</h1>
        </div>
        <div className={OfferMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
        </div>
      </div>
      <main className={OfferMain.mainSection}>
        <div className={OfferMain.offerContainer}>
          <div className={OfferMain.offerBorder}>
            <Link to="/terapia-manualna-wg-koncepcji-maitlanda">
              <img
                src={require("../../../images/OfferImages/M2-removebg-preview.png")}
                alt="M2"
              />
              <h4>Terapia manualna według koncepcji Maitland</h4>
              <p>
                Jest to podejście terapeutyczne, które wykorzystuje techniki
                manualne, np. manipulacje czy mobilizacje, w celu poprawy
                funkcji stawów i zmniejszenia bólu.
              </p>
            </Link>
          </div>
          <div className={OfferMain.offerBorder}>
            <Link to="/terapia-stawów-skroniowo-żuchwowych-crafta">
              <img
                src={require("../../../images/OfferImages/facial-removebg-preview.png")}
                alt="M2"
              />
              <h4>Terapia Stawów Skroniowo-Żuchwowych Crafta</h4>
              <p>
                Jest to podejście terapeutyczne skupiające się na manipulacjach
                stawów skroniowo-żuchwowych w celu złagodzenia bólu oraz
                przywrócenia prawidłowego funkcjonowania tych stawów.
              </p>
            </Link>
          </div>
          <div className={OfferMain.offerBorder}>
            <Link to="/terapia-tkanek-miękkich">
              <img
                src={require("../../../images/OfferImages/pilates1-removebg-preview.png")}
                alt="M2"
              />
              <h4>Terapia Tkanek Miękkich</h4>
              <p>
                Jest to podejście terapeutyczne, które koncentruje się na
                manipulacji mięśni, więzadeł i innych tkanek miękkich w celu
                zmniejszenia bólu, poprawy funkcji oraz przywrócenia
                elastyczności i mobilności ciała.
              </p>
            </Link>
          </div>
          <div className={OfferMain.offerBorder}>
            <Link to="/rehabilitacja-po-operacyjna">
              <img
                src={require("../../../images/OfferImages/pain-in-joints-removebg-preview.png")}
                alt="M2"
              />
              <h4>Rehabilitacja po operacji</h4>
              <p>
                Jest to proces terapeutyczny, który ma na celu przywrócenie
                pełnej funkcjonalności i mobilności ciała po zabiegu
                chirurgicznym, wykorzystując ćwiczenia fizyczne, terapię
                manualną i inne techniki w zależności od rodzaju operacji.
              </p>
            </Link>
          </div>
          <div className={OfferMain.offerBorder}>
            <Link to="/rehabilitacja-seniorów">
              <img
                src={require("../../../images/OfferImages/exercising-senior-removebg-preview.png")}
                alt="M2"
              />
              <h4>Rehabilitacja Seniorów</h4>
              <p>
                Jest to kompleksowy proces terapeutyczny, dostosowany do
                starszych pacjentów, mający na celu poprawę sprawności
                fizycznej, utrzymanie samodzielności oraz zapobieganie utracie
                funkcji poprzez ćwiczenia, terapię zajęciową i edukację
                zdrowotną.
              </p>
            </Link>
          </div>
          <div className={OfferMain.offerBorder}>
            <Link to="/masaż">
              <img
                src={require("../../../images/OfferImages/massage-removebg-preview.png")}
                alt="M2"
              />
              <h4>Masaż</h4>
              <p>
                Jest to technika terapeutyczna wykorzystująca manipulacje tkanek
                miękkich, takich jak mięśnie i tkanki skórne, w celu złagodzenia
                napięcia, redukcji bólu, poprawy krążenia krwi oraz relaksu.
                Zastosowanie różnych technik masażu może wspierać zarówno
                zdrowie fizyczne, jak i psychiczne.
              </p>
            </Link>
          </div>
          <div className={OfferMain.offerBorder}>
            <Link to="/ćwiczenia-indywidualne">
              <img
                src={require("../../../images/OfferImages/exerciseing-removebg-preview.png")}
                alt="M2"
              />
              <h4>Ćwiczenia Indywidualne</h4>
              <p>
                Ćwiczenia indywidualne to treningi dostosowane do konkretnych
                potrzeb i możliwości osoby, mające na celu poprawę siły,
                elastyczności, koordynacji i ogólnej kondycji fizycznej. Ta
                spersonalizowana forma aktywności fizycznej jest zaprojektowana
                pod konkretne cele i warunki danej osoby.
              </p>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};
