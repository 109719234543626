import React from "react";
import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={OfferFirstMain.containerMain}>
        <div className={OfferFirstMain.aboutSection}>
          <h1>Rehabilitacja po operacji</h1>
        </div>
        <div className={OfferFirstMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
          <Link to="/rehabilitacja-po-operacyjna">
            Rehabilitacja po operacji
          </Link>
        </div>
      </div>

      <main className={OfferFirstMain.mainSection}>
        <div className={OfferFirstMain.mainDesc}>
          <div className={OfferFirstMain.textContent}>
            <p>
              <b>Rehabilitacja po operacji</b> jest kluczowym procesem w powrocie do pełni zdrowia i sprawności po zabiegu chirurgicznym.
              Celem rehabilitacji jest przywrócenie funkcji ciała, zwiększenie zakresu ruchu, redukcja bólu oraz wzmacnianie mięśni.
              Program rehabilitacyjny jest dostosowany indywidualnie do potrzeb pacjenta i rodzaju operacji. Regularne sesje rehabilitacji
              pomagają w szybszym powrocie do aktywności, poprawiając również jakość życia pacjenta.
            </p>
          </div>
        </div>
        <div className={OfferFirstMain.imgContainer}>
          <img
            src={require("./../../../images/Offerts/rehabilitacja-po-operacyjna/Zdjęcie9.jpg")}
            alt=""
          />
        </div>
        <div className={OfferFirstMain.row}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Jakie schorzenia leczymy?</h3>
            <span></span>
          </div>
          <p>Stan po:</p>
          <ul>
            <li>zabiegach ortopedycznych</li>
            <li>operacjach kręgosłupa</li>
            <li>operacjach kardiologicznych</li>
            <li>zabiegach chirurgicznych układu trawiennego</li>
            <li>zabiegach w obrębie układu oddechowego</li>
          </ul>
        </div>
        <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Korzyści terapii</h3>
            <span></span>
          </div>
          <ul>
            <li>Przywracanie ruchomości i funkcji ciała</li>
            <li>Redukcja bólu</li>
            <li>Wzmacnianie mięśni</li>
            <li>Poprawa koordynacji i równowagi</li>
            <li>Zmniejszenie obrzęków i poprawa krążenia</li>
          </ul>
        </div>
      </main>
    </>
  );
};
