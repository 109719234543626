import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const Questions = () => {
  return (
    <>
      <Helmet>
        <title>FAQ - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Znajdz odpowiedzi na najczęsciej zadawane pytania dotyczące wizyt domowych i innych kwestji związanych z usługami." />
        <link rel="canonical" href="https://www.fizjocezar.pl/faq" />
      </Helmet>
      <Main />
    </>
  );
};
