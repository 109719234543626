import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferSeventh = () => {
  return (
    <>
      <Helmet>
        <title>Ćwiczenia Indywidualne - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Ćwiczenia indywidualne mają na celu poprawę kondycji fizycznej, redukcję bólu oraz przywrócenie sprawności. Ćwiczenia są dopasowane do stanu zdrowia, możliwości i celów każdej konkretnej osoby." />
        <link rel="canonical" href="https://www.fizjocezar.pl/ćwiczenia-indywidualne" />
      </Helmet>
      <Main />
    </>
  );
};
