import React, { useEffect, useState } from "react";
import OurServiceCSS from "./../../../styles/layouts/HomePage/components/OurService.module.css";
import { Link } from "react-router-dom";

type AvailableDay = string; // Assuming AvailableDay is a string like 'Monday', 'Tuesday', etc.
type DailySchedule = {
  startTime: string;
  endTime: string;
};

type WorkingHours = Record<AvailableDay, DailySchedule>;


export const OurService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [workingHours, setWorkingHours] = useState<WorkingHours | null>(null);
  const [availableDays, setAvailableDays] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAvailableDays = async () => {
    try {
      const response = await fetch(`${apiUrl}/v1/config/getAvailableDays`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: string[] = await response.json();
      setAvailableDays(data);
      setIsLoading(false);
    } catch (err: any) {
      setError(err);
      setIsLoading(false);
    }
  }


  const fetchWorkingHours = async () => {
    try {
      const response = await fetch(`${apiUrl}/v1/config/getWorkingHours`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: WorkingHours = await response.json();
      setWorkingHours(data);
      setIsLoading(false);
    } catch (err: any) {
      setError(err);
      setIsLoading(false);
    }
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${parseInt(hours)}:${minutes}`;
  };


  useEffect(() => {
    fetchWorkingHours();
    fetchAvailableDays();

  }, [])

  return (
    <section className={OurServiceCSS.ourServices}>
      <div className={OurServiceCSS.ourOffer}>
        <div className={OurServiceCSS.sectionTitle}>
          <h1>Nasza oferta</h1>
          <span></span>
        </div>
        <p>Poznaj moją szeroką ofertę</p>
      </div>
      <div className={OurServiceCSS.offer}>
        <div className={OurServiceCSS.offerSection}>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/terapia-manualna-wg-koncepcji-maitlanda" >
              TERAPIA MANUALNA WG KONCEPCJI MAITLANDA{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/terapia-stawów-skroniowo-żuchwowych-crafta">
              TERAPIA STAWÓW SKRONIOWO-ŻUCHWOWYCH CRAFTA{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/terapia-tkanek-miękkich">
              TERAPIA TKANEK MIĘKKICH{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/rehabilitacja-po-operacyjna">
              REHABILITACJA PO OPERACJACH{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/rehabilitacja-seniorów">
              REHABILITACJA SENIORÓW{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/masaż">
              MASAŻ{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <div className={OurServiceCSS.offerSectionBorder}>
            <Link to="/ćwiczenia-indywidualne">
              ĆWICZENIA INDYWIDUALNE{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="22"
                viewBox="0 0 320 512"
              >
                <path
                  stroke="black"
                  strokeWidth="1px"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </Link>
          </div>
          <Link className={OurServiceCSS.lastLink} to="/oferta">
            <button>ZOBACZ WIĘCEJ</button>
          </Link>
        </div>
      </div>
      <div className={OurServiceCSS.workHours}>
        <div className={OurServiceCSS.workHoursContainer}>
          <h4>GODZINY PRACY</h4>
          <span className={OurServiceCSS.sepHolder}>
            <span className={OurServiceCSS.sepLine}></span>
          </span>
        </div>
        <table className={OurServiceCSS.workHoursTable}>
          <tbody>
            <tr>
              <td>Poniedziałek</td>
              <td>{availableDays.includes('MONDAY') ? (workingHours?.MONDAY ? `${formatTime(workingHours.MONDAY.startTime)} - ${formatTime(workingHours.MONDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
            <tr>
              <td>Wtorek</td>
              <td>{availableDays.includes('TUESDAY') ? (workingHours?.TUESDAY ? `${formatTime(workingHours.TUESDAY.startTime)} - ${formatTime(workingHours.TUESDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
            <tr>
              <td>Środa</td>
              <td>{availableDays.includes('WEDNESDAY') ? (workingHours?.WEDNESDAY ? `${formatTime(workingHours.WEDNESDAY.startTime)} - ${formatTime(workingHours.WEDNESDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
            <tr>
              <td>Czwartek</td>
              <td>{availableDays.includes('THURSDAY') ? (workingHours?.THURSDAY ? `${formatTime(workingHours.THURSDAY.startTime)} - ${formatTime(workingHours.THURSDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
            <tr>
              <td>Piątek</td>
              <td>{availableDays.includes('FRIDAY') ? (workingHours?.FRIDAY ? `${formatTime(workingHours.FRIDAY.startTime)} - ${formatTime(workingHours.FRIDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
            <tr>
              <td>Sobota</td>
              <td>{availableDays.includes('SATURDAY') ? (workingHours?.SATURDAY ? `${formatTime(workingHours.SATURDAY.startTime)} - ${formatTime(workingHours.SATURDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
            <tr>
              <td>Niedziela</td>
              <td>{availableDays.includes('SUNDAY') ? (workingHours?.SUNDAY ? `${formatTime(workingHours.SUNDAY.startTime)} - ${formatTime(workingHours.SUNDAY.endTime)}` : 'Zamknięte') : 'Zamknięte'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
