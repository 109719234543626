import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferFourth = () => {
  return (
    <>
      <Helmet>
        <title>Rehabilitacja Po Operacyjna - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Rehabilitacja po operacji jest kluczowym procesem w powrocie do pełni zdrowia i sprawności po zabiegu chirurgicznym." />
        <link rel="canonical" href="https://www.fizjocezar.pl/rehabilitacja-po-operacyjna" />
      </Helmet>
      <Main />
    </>
  );
};
