import React, { useEffect, useState } from "react";
import ConfirmRegistrationCSS from "./../../styles/layouts/ConfirmRegistration/ConfirmRegistration.module.css";
import { useLocation } from 'react-router-dom';

interface ConfirmationResponse {
    status: string;
    message: string;
}

export const ConfirmRegistration = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);
    const [confirmationStatus, setConfirmationStatus] = useState<string | null>(null);


    useEffect(() => {
        const confirmToken = async () => {
            const searchParams = new URLSearchParams(location.search);
            const token = searchParams.get("token");

            if (!token) {
                setConfirmationMessage('Nieprawidłowy token.');
                setConfirmationStatus('error');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/v1/registration/confirm?token=${token}`);
                const data: ConfirmationResponse = await response.json();

                if (response.status === 409 && data.status === "already_confirmed") {
                    // Obsługa przypadku, gdy konto jest już potwierdzone
                    setConfirmationStatus("already_confirmed");
                    setConfirmationMessage("Twoje konto zostało już wcześniej potwierdzone.");
                    return;
                }

                setConfirmationStatus(data.status);
                setConfirmationMessage(data.message);
            } catch (error) {
                setConfirmationStatus('error');
                setConfirmationMessage('Wystąpił problem z potwierdzeniem rejestracji.');
            }
        };

        confirmToken();
    }, [location.search, apiUrl]);
    return (
        <>
            <div className={ConfirmRegistrationCSS.confirmationContainer}>
                {confirmationStatus === "success" && (
                    <div className={ConfirmRegistrationCSS.confirmationSuccess}>
                        <h1>Potwierdzenie Rejestracji</h1>
                        <p>{confirmationMessage}</p>
                    </div>
                )}
                {confirmationStatus === "token_not_found" && (
                    <div className={ConfirmRegistrationCSS.confirmationError}>
                        <h1>Nieprawidłowy Token</h1>
                        <p>{confirmationMessage}</p>
                    </div>
                )}
                {confirmationStatus === "already_confirmed" && (
                    <div className={ConfirmRegistrationCSS.confirmationError}>
                        <h1>Email Już Potwierdzony</h1>
                        <p>{confirmationMessage}</p>
                    </div>
                )}
                {confirmationStatus === "expired" && (
                    <div className={ConfirmRegistrationCSS.confirmationError}>
                        <h1>Token Wygasł</h1>
                        <p>{confirmationMessage}</p>
                        <p>Zarejestruj się ponownie</p>
                    </div>
                )}
                {confirmationStatus === "error" && (
                    <div className={ConfirmRegistrationCSS.confirmationError}>
                        <h1>Problem z Potwierdzeniem</h1>
                        <p>{confirmationMessage}</p>
                    </div>
                )}
            </div>
        </>
    );
};