import React from "react";
import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <>
        <div className={OfferFirstMain.containerMain}>
          <div className={OfferFirstMain.aboutSection}>
            <h1>Terapia Stawów Skroniowo Żuchwowych Crafta</h1>
          </div>
          <div className={OfferFirstMain.homeLink}>
            <Link to="/">Strone główna</Link>
            <Link to="/oferta">Oferta</Link>
            <Link to="/terapia-stawów-skroniowo-żuchwowych-crafta">
              Terapia Stawów Skroniowo Żuchwowych Crafta
            </Link>
          </div>
        </div>
        <main className={OfferFirstMain.mainSection}>
          <div className={OfferFirstMain.mainDesc}>
            <div className={OfferFirstMain.textContent}>
              <p>
                <b>Metoda Crafta</b> jest techniką skupiającą się na diagnozie oraz leczeniu dysfunkcji i bólu w obszarze stawów skroniowo-żuchwowych.
                Opiera się na precyzyjnym badaniu, technikach mobilizacyjnych stawu i opracowaniu tkanek miękkich w okolicach głowy, twarzy i szyi.
              </p>
            </div>
          </div>
          <div className={OfferFirstMain.imgContainer}>
            <img
              src={require("./../../../images/Offerts/terapia-stawów-skroniowo-żuchwowych-crafta/Zdjęcie7.jpg")}
              alt=""
            />
          </div>
          <div className={OfferFirstMain.row}>
            <div className={OfferFirstMain.sectionTitle}>
              <h3>Jakie schorzenia leczymy?</h3>
              <span></span>
            </div>
            <ul>
              <li>Zaburzenia zgryzu</li>
              <li>Bóle głowy</li>
              <li>Szczękościsk</li>
              <li>Problemy z funkcją stawów skroniowo-żuchwowych</li>
              <li>Zaburzenia żucia</li>
              <li>Chroniczne bóle twarzy</li>
            </ul>
          </div>
          <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
            <div className={OfferFirstMain.sectionTitle}>
              <h3>Korzyści terapii</h3>
              <span></span>
            </div>
            <ul>
              <li>Zmniejszenie bólu</li>
              <li>Poprawa ruchomości stawów</li>
              <li>Zwiększenie mobilności</li>
              <li>Redukcja napięcia mięśniowego</li>
              <li>Ograniczenie dyskomfortu</li>
            </ul>
          </div>
        </main>
      </>
    </>
  );
};
