import React, { useEffect, useState } from "react";
import OpinionsSectionCSS from "./../../../styles/layouts/HomePage/components/OpinionsSection.module.css";
import firstImage from "./../../../images/HomePage/review/SandraSliwa.png";
import secondImage from "./../../../images/HomePage/review/MartynaStaniec.png";
import thirdImage from "./../../../images/HomePage/review/AgnieszkaSłaby.png";
import fourthImage from "./../../../images/HomePage/review/JoannaWojciechowska.png";
import fifthImage from "./../../../images/HomePage/review/PawełŚliwa.png";
import sixthImage from "./../../../images/HomePage/review/ZbyszekMormol.png";

export const OpinionsSection = () => {
  const [opinionsPerPage, setOpinionsPerPage] = useState(3);
  const opinionsData = [
    {
      author: "Sandra Śliwa",
      text: "Serdecznie polecam!!! Cezary pomógł bardzo mojemu mężowi wstać na nogi po przeszczepie wątroby i bardzo długiej hospitalizacji. Zawsze uśmiechnięty, cierpliwy. Dzięki ćwiczeniom i mobilizacji blizny przez masaże mój mąż stanął na nogi. Dziękujemy i polecamy :)",
      img: firstImage,
    },
    {
      author: "Martyna Staniec",
      text: "Bardzo polecam pracę z P. Cezarym. Rehabilitacja po rekonstrukcji ACL i szyciu łąkotki przebiegła sprawnie, a ćwiczenia dostosowane były do możliwości w danym momencie. Każde spotkanie przebiegało w przyjaznej atmosferze, a Czarek zawsze słuchał moich uwag i starał się pomagać, gdy pojawiało się coś, co mnie martwiło. Na plus zasługuje też kontakt z nim. Nigdy nie było problemu, by przełożyć termin spotkania. Gorąco polecam współpracę z tym panem :)",
      img: secondImage,
    },
    {
      author: "Agnieszka Słaby",
      text: "Bardzo dobry fizjoterapeuta, zaangażowany w pracę z pacjentem i usprawnienie rehabilitowanych obszarów. Ja korzystałam z usług po rekonstrukcji więzadła ( przeszczep autogeniczny) i szyciu oraz usunięciu części łąkotki. Rehabilitację zaczęłam z Panem Czarkiem tydzień po operacji, po 4 tygodniach byłam już bez kul😊 Po 3 miesiącach od operacji kolano ma pełny zakres zgięcia i wyprostu, nie boli i mogę normalnie funkcjonować l. Polecam!!!",
      img: thirdImage,
    },
    {
      author: "Joanna Wojciechowska",
      text: "P.Czarek jest kompetentny,rzetelny i świetny specjalista. Rehabilitacja domowa przebiega w skupieniu , jest plan działania. Praktycznie nie mogłam się poruszać, a w tej chwili robię duże postępy. Zajęcia przebiegają w miłej i przyjaznej atmosferze. Z czystym sumieniem polecam P. Czarka .",
      img: fourthImage,
    },
    {
      author: "Paweł Śliwa",
      text: "Polecam serdeczne! Pomogł mi wstać na nogi po przeszczepie wątroby ;-)",
      img: fifthImage,
    },
    {
      author: "Zbyszek Mormol",
      text: "Bardzo skutecznie i miło. Serdecznie polecam💪",
      img: sixthImage,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastOpinion = currentPage * opinionsPerPage;
  const indexOfFirstOpinion = indexOfLastOpinion - opinionsPerPage;
  const currentOpinions = opinionsData.slice(
    indexOfFirstOpinion,
    indexOfLastOpinion
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(opinionsData.length / opinionsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setOpinionsPerPage(1); // Dla małych ekranów pokaż jedną opinię
    } else if (window.innerWidth < 1024) {
      setOpinionsPerPage(2); // Dla średnich ekranów pokaż dwie opinie
    } else {
      setOpinionsPerPage(3); // Dla większych ekranów pokaż trzy opinie
    }
  };

  useEffect(() => {
    // Dodaj nasłuchiwanie na zmiany szerokości okna
    window.addEventListener("resize", handleResize);

    // Wywołaj handleResize przy pierwszym załadowaniu komponentu
    handleResize();

    // Oczyść nasłuchiwanie zdarzeń przy odmontowaniu komponentu
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

  const handleExpandClick = (index: number) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  return (
    <>
      <section className={OpinionsSectionCSS.opinionsSection}>
        <div className={OpinionsSectionCSS.sectionTitle}>
          <h1>Opinie naszych klientów</h1>
          <span></span>
        </div>
        <div className={OpinionsSectionCSS.options}>
          {currentOpinions.map((opinion, index) => (
            <div className={OpinionsSectionCSS.opinion} key={index}>
              <img
                className={OpinionsSectionCSS.logo}
                src={opinion.img}
                alt="fc"
              />
              <h4>{opinion.author}</h4>
              {expandedIndexes.includes(index) ? (
                <p>{opinion.text}</p>
              ) : (
                <>
                  <p>{opinion.text.slice(0, 150)}</p>
                  {opinion.text.length > 150 && (
                    <a onClick={() => handleExpandClick(index)}>Pokaż więcej</a>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        {/* Przyciski do nawigacji między stronami opinii */}
        <div className={OpinionsSectionCSS.pagination}>
          {pageNumbers.map((number) => (
            <button
              className={
                currentPage === number ? OpinionsSectionCSS.active : ""
              }
              key={number}
              onClick={() => paginate(number)}
            ></button>
          ))}
        </div>
      </section>
    </>
  );
};
