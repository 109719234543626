import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const Offer = () => {
  return (
    <>
      <Helmet>
        <title>Oferta - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Oferuje szeroki zakres usług rehabilitacyjnych dostosowanych do indywidualnych potrzeb pacjenta takich jak terapia manualna, ćwiczenia, masaż." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.fizjocezar.pl/oferta" />
      </Helmet>
      <Main />
    </>
  );
};
