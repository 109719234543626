import React, { useEffect, useState } from "react";
import AccountMain from "./../../../styles/layouts/Account/components/Main.module.css";
import { Link } from "react-router-dom";

enum BookingStatus {
  SCHEDULE = "SCHEDULE",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

interface Booking {
  id: number;
  bookingDate: string;
  bookingStatus: BookingStatus;
  message: string;
  isConfirmed: boolean;
}

const CompletedAndCanceledBookings: React.FC<{ userId: number | undefined }> = ({
  userId,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [bookings, setBookings] = useState<Booking[]>([]);

  const token = localStorage.getItem("token");

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: "UTC" // Ustawienie strefy czasowej na UTC
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC" // Ustawienie strefy czasowej na UTC
    };
    return `${date.toLocaleDateString(
      "pl-GB",
      optionsDate
    )} ${date.toLocaleTimeString("pl-GB", optionsTime)}`;
  };

  function getBookingStatusClass(status: BookingStatus) {
    switch (status) {
      case BookingStatus.COMPLETED:
        return "Ukończona";
      case BookingStatus.CANCELLED:
        return "Anulowana";
      case BookingStatus.SCHEDULE:
        return "Zaplanowana";
      default:
        return "Nieznany status";
    }
  }

  useEffect(() => {
    const fetchCompletedAndCanceledBookings = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/v1/booking/getCompletedAndCanceledBookingByUser/${userId}`,
          // `http://localhost:8080/api/v1/booking/getCompletedAndCanceledBookingByUser/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        if (response.status === 204) {
          setBookings([]);
          return;
        }
        const data: Booking[] = await response.json();
        setBookings(data);
      } catch (error) {
        console.error("Failed to fetch scheduled bookings:", error);
      }
    };

    fetchCompletedAndCanceledBookings();
  }, [userId, token]);

  return (
    <>
      {bookings.length > 0 ? (
        <div className={AccountMain.nextReservation}>
          <table className={AccountMain.appointmentTable}>
            <thead>
              <tr>
                <td>Kiedy</td>
                <td>Cena</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {bookings.map((bookings) => (
                <tr key={bookings.id}>
                  <td>
                    <div> {formatDate(bookings.bookingDate)} </div>
                  </td>
                  <td>200 zł</td>
                  <td className={getBookingStatusClass(bookings.bookingStatus)}>
                    {getBookingStatusClass(bookings.bookingStatus)}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={AccountMain.nextReservationBtn}>
            <Link to="/zarezerwuj">ZAREZERWUJ</Link>
          </div>
        </div>
      ) : (
        <div className={AccountMain.nextReservation}>
          <p>Nie masz nadchodzących rezerwacji.</p>
          <div className={AccountMain.nextReservationBtn}>
            <Link to="/zarezerwuj">ZAREZERWUJ</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CompletedAndCanceledBookings;
