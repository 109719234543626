import { useState, useEffect, useCallback } from "react";
import { ArrowBigLeft, ArrowBigRight, Circle, CircleDot } from "lucide-react";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import ImageSliderCSS from "./../../../styles/layouts/HomePage/components/ImageSlider.module.css";

type ImageSliderProps = {
    images: {
        url: string;
        alt: string;
    }[];
};

export function ImageSlider({ images }: ImageSliderProps) {
    const [imageIndex, setImageIndex] = useState(0);

    const showNextImage = useCallback(() => {
        setImageIndex((index) => (index === images.length - 1 ? 0 : index + 1));
    }, [images.length]);

    function showPrevImage() {
        setImageIndex((index) => (index === 0 ? images.length - 1 : index - 1));
    }

    const scrollToPhysiotherapyWarsawSection = () => {
        const element = document.getElementById("fizjoterapia-warszawa");
        if (element) {
            const offset = element.offsetTop - 80;
            scroll.scrollTo(offset, {
                smooth: true,
                duration: 500,
            });
        }
    };

    useEffect(() => {
        const interval = setInterval(showNextImage, 5000); // Zmiana co 5 sekund
        return () => clearInterval(interval); // Czyść timer po odmontowaniu komponentu
    }, [showNextImage]);

    return (
        <section aria-label="Image Slider" style={{ width: "100%", height: "100%", position: "relative" }}>
            <a href="#after-image-slider-controls" className={ImageSliderCSS.skipLink}>
                Skip Image Slider Controls
            </a>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    overflow: "hidden",
                }}
            >
                {images.map(({ url, alt }, index) => (
                    <div
                        key={url}
                        className={ImageSliderCSS.imgContainer}
                        style={{ transform: `translateX(${-100 * imageIndex}%)` }}
                    >
                        <img
                            src={url}
                            alt={alt}
                            aria-hidden={imageIndex !== index}
                            className={ImageSliderCSS.imgSliderImg}
                        />
                    </div>
                ))}
                <div className={ImageSliderCSS.textContent}>
                    <div className={ImageSliderCSS.textContentContainer}>
                        <h1>Fizjoterapia na wyciągnięcie ręki</h1>
                        <h1>Dojadę do Ciebie!</h1>
                        <p>Nie musisz się martwić o dojazd - przyjadę do Ciebie, </p>
                        <p>by dostarczyć Ci kompleksową opiekę fizjoterapeutyczną.</p>
                        <div className={ImageSliderCSS.buttonContainer}>
                            <button className={ImageSliderCSS.learnMoreBtn} onClick={scrollToPhysiotherapyWarsawSection}>
                                DOWIEDZ SIĘ WIĘCEJ
                            </button>
                            <Link to="/zarezerwuj"><button className={ImageSliderCSS.reserveBtn}>ZAREZERWUJ</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <button
                onClick={showPrevImage}
                className={ImageSliderCSS.imgSliderBtn}
                style={{ left: 0 }}
                aria-label="View Previous Image"
            >
                <ArrowBigLeft aria-hidden />
            </button>
            <button
                onClick={showNextImage}
                className={ImageSliderCSS.imgSliderBtn}
                style={{ right: 0 }}
                aria-label="View Next Image"
            >
                <ArrowBigRight aria-hidden />
            </button>
            <div
                style={{
                    position: "absolute",
                    bottom: ".5rem",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    gap: ".25rem",
                }}
            >
                {images.map((_, index) => (
                    <button
                        key={index}
                        className={ImageSliderCSS.imgSliderDotBtn}
                        aria-label={`View Image ${index + 1}`}
                        onClick={() => setImageIndex(index)}
                    >
                        {index === imageIndex ? (
                            <CircleDot aria-hidden />
                        ) : (
                            <Circle aria-hidden />
                        )}
                    </button>
                ))}
            </div>
            <div id="after-image-slider-controls" />
        </section>
    );
}
