import React from "react";
import HeaderCSS from "./../../../styles/layouts/HomePage/components/Header.module.css";

export const Header = () => {
  return (
    <header>
      <div className={HeaderCSS.contactInfo}>
        <img src={require("../../../images/HomePage/Header/phone-solid.png")} alt="phone" />
        <p>518 414 650</p>
        <img src={require("../../../images/HomePage/Header/envelope-solid.png")} alt="mail" />
        <p>info@fizjocezar.pl</p>
      </div>
      <div className={HeaderCSS.socialMedia}>
        <a href="https://www.instagram.com/fizjo_domowki_bialoleka">
          <img src={require("../../../images/HomePage/Header/instagram.png")} alt="instagram" />
        </a>
        <a href="https://www.facebook.com/fizjocezar">
          <img src={require("../../../images/HomePage/Header/facebook.png")} alt="facebook" />
        </a>
      </div>
    </header>
  );
};
