import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import NavBarCSS from "../../styles/layouts/NavBarAndFooter/NavBar.module.css";
import RegistrationForm from "../RegistrationAndLogin/RegistrationForm";
import LoginForm from "../RegistrationAndLogin/LoginForm";
import AppUserModle from "../../models/AppUserModel";
import getUserData from "../../utils/UserService";
import { getToken, isTokenExpired } from "../../utils/auth";

export const Navbar = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<AppUserModle | null>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const userRole = localStorage.getItem("userRole");

  const token = localStorage.getItem("token");
  if (!token) {
    console.log("Brak tokenu.");
  }

  useEffect(() => {

    const handleOutsideClick = (event: { target: any }) => {
      if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
        checkboxRef.current.checked = false;
      }
    };

    if (token) {
      getUserData(token).then((data) => {
        setUserData(data);
      });
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const token = getToken();
    if (token && !isTokenExpired(token)) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setUserData(null);
    }
  }, []);

  const toggleLoginPopup = () => {
    setIsLoginPopupOpen(!isLoginPopupOpen);
  };

  const toggleVisibility = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  const handleLogout = async () => {
    try {
      await fetch(
        `${apiUrl}/v1/logout`,
        {
          method: "POST",
          credentials: "include",
        });

      localStorage.removeItem("token");
      localStorage.removeItem("userRole");

      setIsLoggedIn(false);
    } catch (error) {
      console.error("Błąd podczas wylogowywania", error);
    }
  };

  return (
    <div className={NavBarCSS.container}>
      <div className={NavBarCSS.navbarLogo}>
        <NavLink to="/">
          <img
            src={require("./../../images/NavBarAndFooter/NavBar/logoNapisPoziomNiebieskie.png")}
            className="navbar-logo"
            width="270"
            height="auto"
            alt="logo"
          />
        </NavLink>
      </div>
      <div className={NavBarCSS.navbarContainer}>
        <nav id="navbar" className={NavBarCSS.navbar}>
          <input
            ref={checkboxRef}
            type="checkbox"
            className={NavBarCSS.hamburgerMenu}
          />
          <label></label>
          <ul className={NavBarCSS.navbarList}>
            <li className={NavBarCSS.navbarItem}>
              <NavLink activeClassName={NavBarCSS.activeLink} to="/o-mnie">
                O MNIE
              </NavLink>
            </li>
            <li className={NavBarCSS.navbarItem}>
              <NavLink activeClassName={NavBarCSS.activeLink} to="/cennik">
                CENNIK
              </NavLink>
            </li>
            <li className={NavBarCSS.navbarItem}>
              <NavLink
                activeClassName={NavBarCSS.activeLink}
                to="/pierwsza-wizyta"
              >
                PIERWSZA WIZYTA
              </NavLink>
            </li>
            <li className={NavBarCSS.navbarItem}>
              <NavLink activeClassName={NavBarCSS.activeLink} to="/oferta">
                OFERTA
              </NavLink>
            </li>
            <li className={NavBarCSS.navbarItem}>
              <NavLink activeClassName={NavBarCSS.activeLink} to="/faq">
                PYTANIA
              </NavLink>
            </li>
            <li className={NavBarCSS.navbarItem}>
              <NavLink activeClassName={NavBarCSS.activeLink} to="/kontakt">
                KONTAKT
              </NavLink>
            </li>
            <li className={NavBarCSS.navbarItem}>

              {isLoggedIn ? (
                <Link to="/" onClick={handleLogout}>
                  Wyloguj
                </Link>
              ) : (
                <div></div>
              )}

            </li>
          </ul>
        </nav>
        <div className={NavBarCSS.navbarLogin}>
          <div className={NavBarCSS.navbarItemLogin}>
            {isLoggedIn ? (
              <div className={NavBarCSS.profileContainer}>
                {userRole === "ADMIN_ROLE" ? (
                  <NavLink to="/konto/admin" className={NavBarCSS.profileLink}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                      width="40"
                      fill="lightgrey"
                      viewBox="0 0 512 512"
                    >
                      <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
                    </svg>
                  </NavLink>
                ) : (
                  <NavLink
                    to="/konto/uzytkownik"
                    className={NavBarCSS.profileLink}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                      width="40"
                      fill="lightgrey"
                      viewBox="0 0 512 512"
                    >
                      <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
                    </svg>
                  </NavLink>
                )}
                <div className={NavBarCSS.logout}>
                  <h3>
                    {userData
                      ? `${userData.firstName} ${userData.lastName}`
                      : "Loading..."}
                  </h3>
                  <Link to="/" onClick={handleLogout}>
                    Wyloguj
                  </Link>
                </div>
              </div>
            ) : (
              <button
                className={NavBarCSS.singIn}
                onClick={toggleLoginPopup}
              >
                ZALOGUJ
              </button>
            )}
          </div>
          <div className={NavBarCSS.navbarItemLogin}>
            <NavLink to="/zarezerwuj" className={NavBarCSS.bookBtn}>
              ZAREZERWUJ
            </NavLink>
          </div>

          {isLoginPopupOpen && (
            <div>
              {isLoginVisible ? (
                <LoginForm
                  toggleVisibility={toggleVisibility}
                  toggleLoginPopup={toggleLoginPopup}
                />
              ) : (
                <RegistrationForm
                  toggleVisibility={toggleVisibility}
                  toggleLoginPopup={toggleLoginPopup}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
