import AppUserModle from "../models/AppUserModel";

const apiUrl = process.env.REACT_APP_API_URL;

const getUserData = async (token: string | null): Promise<AppUserModle | null> => {
  if (!token) {
    return null;
  }
  try {
    const response = await fetch(`${apiUrl}/v1/user/data`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    localStorage.setItem("userRole", data.userRole);

    return new AppUserModle(
      data.id,
      data.firstName,
      data.lastName,
      data.password,
      data.email,
      data.phoneNumber,
      data.dateOfBirth,
      data.gender,
      data.address,
      data.userRole,
      data.locked,
      data.enabled
    );
  } catch (error) {
    return null;
  }
};

export default getUserData;
