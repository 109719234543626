import React from "react";
import OfferFirstMain from "./../../../styles/layouts/OfferFirst/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={OfferFirstMain.containerMain}>
        <div className={OfferFirstMain.aboutSection}>
          <h1>Masaż</h1>
        </div>
        <div className={OfferFirstMain.homeLink}>
          <Link to="/">Strone główna</Link>
          <Link to="/oferta">Oferta</Link>
          <Link to="/masaż">Masaż</Link>
        </div>
      </div>

      <main className={OfferFirstMain.mainSection}>
        <div className={OfferFirstMain.mainDesc}>
          <div className={OfferFirstMain.textContent}>
            <p>
              <b>Masaż</b> to metoda, która poprzez zastosowanie różnorodnych technik, takich jak głaskanie, rozcieranie, ugniatanie,
              uderzanie mają zredukować napięcia mięśniowe, poprawić krążenie krwi, limfy i wspomaga proces regeneracji tkanek.
            </p>
          </div>
        </div>
        <div className={OfferFirstMain.imgContainer}>
          <img
            src={require("./../../../images/Offerts/masaż/Zdjęcie11.jpg")}
            alt=""
          />
        </div>
        <div className={OfferFirstMain.row}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Jakie schorzenia leczymy?</h3>
            <span></span>
          </div>
          <ul>
            <li>Bóle mięśniowe</li>
            <li>Bóle kręgosłupa</li>
            <li>Stres i napięcie</li>
            <li>Naprawa tkanek</li>
          </ul>
        </div>
        <div className={`${OfferFirstMain.row} ${OfferFirstMain.row2}`}>
          <div className={OfferFirstMain.sectionTitle}>
            <h3>Korzyści terapi</h3>
            <span></span>
          </div>
          <ul>
            <li>Redukcja bólu</li>
            <li>Poprawa krążenia</li>
            <li>Relaksacja mięśni</li>
            <li>Redukcja stresu</li>
          </ul>
        </div>
      </main>
    </>
  );
};
