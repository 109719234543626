class BookingData {
  date: Date | null;
  userId: number;
  message: string;

  constructor(date: Date | null, userId: number, message: string) {
    this.date = date;
    this.userId = userId;
    this.message = message;
  }
}

export default BookingData;
