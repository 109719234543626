import { useEffect } from "react";
import { useHistory } from "react-router-dom"
import { getToken, isTokenExpired, removeToken } from "./auth";

const useAuth = () => {
    const history = useHistory();

    useEffect(() => {
        const checkTokenExpiration = () => {
            const token = getToken();
            if (token && isTokenExpired(token)) {
                alert("Twoja sesja wygasłą. Proszę się zalogować ponownie");
                removeToken();
                history.push("/");
            }
        };

        const intervalId = setInterval(checkTokenExpiration, 60000);

        checkTokenExpiration();

        return () => clearInterval(intervalId);
    }, [history]);

    return null;
}

export default useAuth;