import React from "react";
import FooterCSS from "../../styles/layouts/NavBarAndFooter/Footer.module.css";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
      <footer>
        <div className={FooterCSS.contact}>
          <h1>Kontakt</h1>
          <div className={FooterCSS.contactImg}>
            <img
              src={require("./../../images/NavBarAndFooter/Footer/phone-solid-blue.png")}
              alt="phone"
            />
            <p>518 414 650</p>
          </div>
          <div className={FooterCSS.contactImg}>
            <img
              src={require("./../../images/NavBarAndFooter/Footer/envelope-solid-blue.png")}
              alt="mail"
            />
            <p>info@fizjocezar.pl</p>
          </div>
        </div>
        <div className={FooterCSS.aboutUs}>
          <h1>O Mnie</h1>
          <Link to="/o-mnie">O firmie</Link>
          <Link to="/pierwsza-wizyta">Pierwsza Wizyta</Link>
          <Link to="/oferta">Oferta</Link>
        </div>
        <div className={FooterCSS.documents}>
          <h1>Dokumenty</h1>
          <Link to="/regulamin-organizacyjny">Regulamin organizacyjny</Link>
          <Link to="/polityka-prywatności">Polityka prywatności</Link>
        </div>
      </footer>
      <div className={FooterCSS.copyright}>
        <p> Copyright © 2024 FizjoCezar mgr Cezary Ciecierski</p>
        <p>Strona stworzona przez: Piotr Rakocki</p>
      </div>
    </div>
  );
};
