import React, { useEffect, useState } from "react";
import AccountMain from "./../../../../styles/layouts/Account/components/Main.module.css";

enum QuestionType {
  AUTHENTICATED_USER,
  ANONYMOUS_USER,
}

interface CommonQuestionDisplayDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  topic: string;
  question: string;
  answer: string;
  questionType: QuestionType;
  answered: boolean;
  questionTime: string;
}

const MessagesComponent: React.FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [selectedMessage, setSelectedMessage] = useState<number | null>(null);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState<CommonQuestionDisplayDTO[]>([]);
  const [error, setError] = useState<string | null>(null);

  const getQuestions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${apiUrl}/v1/commonQuestion/getAllQuestions`,
        // "http://localhost:8080/api/v1/commonQuestion/getAllQuestions",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 204) {
        setQuestions([]);
      } else if (!response.ok) {
        throw new Error("Błąd podczas ładowania danych");
      } else {
        const data = await response.json();
        // console.log(data);
        setQuestions(data);
      }
    } catch (err) {
      setError("Nie udało się załadować pytań");
    } finally {
      setIsLoading(false);
    }
  };

  const sendAnswerMessage = async (data: {
    id: number;
    questionType: QuestionType;
    email: string;
    topic: string;
    answer: string;
  }) => {
    // const url = "http://localhost:8080/api/v1/commonQuestion/answerMessage";
    const url = `${apiUrl}/v1/commonQuestion/answerMessage`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      // console.log("Message sent successfully");
      return true; // Zwraca true, gdy wiadomość zostanie pomyślnie wysłana
    } catch (error) {
      console.error("Error sending message:", error);
      return false; // Zwraca false, gdy wystąpi błąd
    }
  };

  const handleSendAnswer = async () => {
    if (selectedMessage === null) return;

    const question = questions[selectedMessage];
    const data = {
      id: question.id,
      questionType: question.questionType,
      email: question.email,
      topic: question.topic,
      answer: question.answer,
    };

    const isSuccess = await sendAnswerMessage(data);

    if (isSuccess) {
      const updatedQuestions = [...questions]; // Klonowanie obecnego stanu
      updatedQuestions[selectedMessage] = { ...question, answered: true }; // Aktualizacja konkretnego pytania
      setQuestions(updatedQuestions);
      // console.log("updatedQuestions: ", updatedQuestions);
    }
  };

  const handleSingleMessageClick = (id: number) => {
    setSelectedMessage(id);
  };

  function formatQuestionTime(questionTime: string): string {
    const currentTime = new Date();
    const questionDate = new Date(questionTime);
    const differenceInSeconds = Math.floor(
      (currentTime.getTime() - questionDate.getTime()) / 1000
    );
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;

    if (differenceInSeconds < hour) {
      return `${Math.floor(differenceInSeconds / minute)} minut temu`;
    } else if (differenceInSeconds < day) {
      return `${Math.floor(differenceInSeconds / hour)} godzin temu`;
    } else if (differenceInSeconds < month) {
      return `${Math.floor(differenceInSeconds / day)} dni temu`;
    } else if (differenceInSeconds < year) {
      return `${Math.floor(differenceInSeconds / month)} miesięcy temu`;
    } else {
      return `${Math.floor(differenceInSeconds / year)} lat temu`;
    }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className={AccountMain.messages}>
      <div className={AccountMain.messagesContainer}>
        <div className={AccountMain.messagesListContainer}>
          <div className={AccountMain.messagesHeader}>
            <h5>Skrzynka odbiorcza</h5>
          </div>
          <div className={AccountMain.messagesList}>
            {questions.map((question, index) => (
              <div
                key={index}
                className={`${AccountMain.singleMessage} ${
                  selectedMessage === index ? AccountMain.selectedMessage : ""
                } ${
                  question.answered === false
                    ? AccountMain.unansweredMessage
                    : ""
                }`}
                onClick={() => handleSingleMessageClick(index)}
              >
                <h5>
                  {question.firstName} {question.lastName}
                </h5>
                <p>{question.topic}</p>
                <p>{formatQuestionTime(question.questionTime)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={AccountMain.messagesContent}>
          {selectedMessage === null ? (
            <div className={AccountMain.noMessage}>
              <p>Nie wybrano wiadomości</p>
            </div>
          ) : (
            <div className={AccountMain.answerMessage}>
              <div className={AccountMain.answerTitleName}>
                <h5>
                  {questions[selectedMessage].firstName}{" "}
                  {questions[selectedMessage].lastName}
                </h5>
                <p>{questions[selectedMessage].topic}</p>
              </div>
              <div className={AccountMain.answerContent}>
                <div className={AccountMain.question}>
                  <p>{questions[selectedMessage].question}</p>
                </div>
                <div className={AccountMain.answer}>
                  {questions[selectedMessage].answered ? (
                    <p>{questions[selectedMessage].answer}</p>
                  ) : (
                    <>
                      <textarea
                        value={questions[selectedMessage]?.answer || ""}
                        onChange={(e) => {
                          const updatedQuestions = [...questions];
                          updatedQuestions[selectedMessage] = {
                            ...updatedQuestions[selectedMessage],
                            answer: e.target.value,
                          };
                          setQuestions(updatedQuestions);
                        }}
                      ></textarea>
                      <button onClick={handleSendAnswer}>WYŚLIJ</button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagesComponent;
