import React from "react";
import ConfirmDialogCSS from "../../../styles/layouts/Account/components/ConfirmDialog.module.css";

interface ConfirmDialogProps {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  message,
  onConfirm,
  onCancel,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={ConfirmDialogCSS.confirmDialogOverlay}>
      <div className={ConfirmDialogCSS.confirmDialog}>
        <div className={ConfirmDialogCSS.confirmDialogMessage}>{message}</div>
        <div className={ConfirmDialogCSS.confirmDialogActions}>
          <button
            onClick={onConfirm}
            className={ConfirmDialogCSS.confirmButton}
          >
            Tak
          </button>
          <button onClick={onCancel} className={ConfirmDialogCSS.cancelButton}>
            Nie
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
