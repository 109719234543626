import { jwtDecode } from "jwt-decode";

export const setToken = (token: string) => {
    localStorage.setItem("token", token);
}

export const getToken = (): string | null => {
    return localStorage.getItem("token");
}

export const removeToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
}

interface TokenPayload {
    exp: number;
    [key: string]: any;
}

export const isTokenExpired = (token: string): boolean => {
    const decoded: TokenPayload = jwtDecode(token);
    if (decoded.exp * 1000 < Date.now()) {
        return true;
    }
    return false;
}
